import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from './Modal'; // Make sure you have this component

function AdminSelectTrophyStyle() {
  const location = useLocation();
  const navigate = useNavigate();
  const eventId = location.state?.eventId;
  const [trophyStyles, setTrophyStyles] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    // Fetch trophy styles from the database
    axios.get('/api/getTrophyStyles')
      .then(response => {
        setTrophyStyles(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the trophy styles!', error);
      });
  }, []);

  const handleTrophyStyleSelect = (style) => {
    setSelectedStyle(style);
    setShowModal(true); // Show the confirmation modal
  };

  const confirmTrophyStyle = () => {
  axios.post('/api/updateTrophyStyleForEvent', { eventId, trophystyleId: selectedStyle.id })
    .then(response => {
      setShowModal(false); // Close the current modal
      setShowSuccessDialog(true); // Show the success dialog
    })
    .catch(error => {
      console.error('Error updating the trophy style:', error);
    });
  };

  return (
    <>
      <div className="homeblock text-center trophies" id="trophies">
        <div className="trophytitle">Select Trophy Style</div>
        <div className="trophiescontainer mb-2">
          {trophyStyles.map(style => (
            <div key={style.id} className="trophycontainer">
              <div className="trophystyletrophy bg-gray-200" onClick={() => handleTrophyStyleSelect(style)}>
                <img
                  src={`/images/collections/${style.id}/web/${style.url}`}
                  alt={style.name}
                  className="trophy-image"
                />
                {/* Updated class name and added additional styling for centering */}
              </div>
              <div className="trophystylename text-center">{style.name}</div>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="newtrophymodal">
            <h2>Confirm Trophy Style Selection</h2>
            <div className="newtrophymodalimagewrapper">
              <img
                src={`/images/collections/${selectedStyle.id}/web/${selectedStyle.url}`}
                alt={selectedStyle.name}
                className="newtrophymodalimage"
              />
            </div>
            <div className="newtrophymodalstylename">{selectedStyle.name}</div>
            <div className="newtrophymodalstylebutton newtrophymodalstylebuttonleft" onClick={() => setShowModal(false)}>Cancel</div>
            <div className="newtrophymodalstylebutton" onClick={confirmTrophyStyle}>Confirm</div>

          </div>
        </Modal>
      )}

      {showSuccessDialog && (
        <div className="success-dialog">
          <div className="dialog-content">
            <h2 className="generalmodaltitle">Success!</h2>
            <br />
            <p>Event successfully created.</p>
            <br /><br />
            <button className="big-btn" onClick={() => navigate('/AdminHome')}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminSelectTrophyStyle;
