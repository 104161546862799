import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { UserContext } from './App';

function UserTrophy() {
  const { trophyId } = useParams(); // This will get the trophyId from the URL parameter
  const [trophy, setTrophy] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [trophyOrderDetails, setTrophyOrderDetails] = useState({ order: null, total: null });
  const [trophyImageUrl, setTrophyImageUrl] = useState('');
  const [shareText, setShareText] = useState('');
  const [facebookShareUrl, setFacebookShareUrl] = useState('');
  const [twitterShareUrl, setTwitterShareUrl] = useState('');




  useEffect(() => {
    if(user.userId) {
      axios.get(`/api/getTrophy/${trophyId}/${user.userId}`)
        .then(response => {
          setTrophy(response.data.trophy);
          // Update the state with the order and total information
          setTrophyOrderDetails({
            order: response.data.order,
            total: response.data.total
          });

          if(trophy && user) {
            setTrophyImageUrl(`https://app.yeess.io/images/collections/${trophy.trophystyle_id}/web/${getTrophyName2(trophy.name)}.png`);

            setShareText(`Hey, I just got this amazing ${getTrophyName(trophy.name)} trophy from ${trophy.event_name} ${trophy.division_name}`);

            setFacebookShareUrl(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(trophyImageUrl)}&quote=${encodeURIComponent(shareText)}`);

            setTwitterShareUrl(`https://twitter.com/intent/tweet?url=${encodeURIComponent(trophyImageUrl)}&text=${encodeURIComponent(shareText)}`);
          }
      })
    }
  }, [trophyId, trophy, user.userId]); // This effect will re-run if the trophyId changes

  if (!trophy) {
    return <div>Loading trophy details...</div>;
  }

  function handleNotImplemented() {
    alert("This feature is disabled at the moment");
  }

  function getTrophyName(trophyName) {
    const nameMap = {
      'tiers1': 'Gold',
      'tiers2': 'Silver',
      'tiers3': 'Bronze',
      'tiers4': 'Participation',
      'tiers5': 'MVP'
    };

    return nameMap[trophyName] || trophyName; // default to trophyName if not found in the map
  }

  function getTrophyName2(trophyName) {
    const nameMap = {
      'tiers1': 'gold',
      'tiers2': 'silver',
      'tiers3': 'bronze',
      'tiers4': 'participation',
      'tiers5': 'mvp'
    };

    return nameMap[trophyName] || trophyName; // default to trophyName if not found in the map
  }

  return (
    <>
    <div className="trophy-details">
      <div className="trophybigimage">
        <div className="trophybigimagecontent">
        <img
          src={`/images/collections/${trophy.trophystyle_id}/web/${getTrophyName2(trophy.name)}.png`}
          alt={getTrophyName(trophy.name)}
        />
        </div>
      </div>

    </div>

      <div className="vvblock">
        <h1 className="bigtrophytitle">{getTrophyName(trophy.name)}</h1>
        <div className="bigtropydesc">
        {trophy.event_name}<br/>
        {trophy.division_name}
        </div>
        <div className="bigtrophynumber">Number {trophyOrderDetails.order} / {trophyOrderDetails.total}</div>
      </div>
      <br/>
      <div>
        <div className="float-left mr-2 bigtrophyshare">
          Share
        </div>
        <div className="socialshares">
          <a href="#" className="shareball hidden">
            <img src="/images/logo_google.png"/>
          </a>
          <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer" className="shareball">
            <img src="/images/logo_fb.png"/>
          </a>
          <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className="shareball">
            <img src="/images/logo_x.png"/>
          </a>
          <a href="" className="shareball hidden">
            <img src="/images/logo_ig.png"/>
          </a>
        </div>
        <br clear="all"/>
      </div>
      <br/>
      <div className="bigtrophytext hidden">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus rhoncus gravida dolor ut aliquam.
      </div>
      <div className="vvblock transfertrophy" onClick={handleNotImplemented}>
        <img src="/images/cryptowallet.png"/>
        Transfer trophy to external crypto wallet
      </div>
      <br clear="all"/>

      {/* Add any additional details you'd like to show */}
      </>
  );
}

export default UserTrophy;
