import React from 'react';

const Modal = ({ children, onClose }) => {
  return (
    <div className="modal-uni-backdrop">
      <div className="modal-uni">
        {children}
        <button className="modal-uni-close" onClick={onClose}>×</button>
      </div>
    </div>
  );
};

export default Modal;
