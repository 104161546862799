function Home() {
  return (
    <>
    <div id="modalwrapper">

    <a id="modal" href="/Trophy" className="bg-blue-900">&#xa0;</a>
    &#xa0;
    </div>
    <div className="h-screen main">

      <div className="trophyblock">
        <div className="" id="bigtrophy">
           <a id="bigtrophyarrow" className="blue-500" href="/">&larr;</a>
           <div id="bigtrophyname" className="font-bold">
              GOLD
           </div>
          </div>
      </div>

      <div className="trophyblock" id="bigtrophyinfo">
        <div className="text-xl font-bold text-left" id="bigtrophytitle">Gold Medal</div>
        <div className="text-xl">Paris Summer Olympics, 1924</div>
        <div className="text-xl">5000 metres</div>
        <div className="text-xl">Men</div>
        <div className="text-xl bigtrophycount">1 / 11</div>

      </div>


      <div className="trophyblock" id="bigtrophyinfo">

        <div className="shareItem text-xl">
          Share
        </div>
        <div className="shareItem">
          <div className="shareIcon bg-blue-500">&#xa0;</div>
        </div>
        <div className="shareItem">
          <div className="shareIcon bg-blue-500">&#xa0;</div>
        </div>
        <div className="shareItem">
          <div className="shareIcon bg-blue-500">&#xa0;</div>
        </div>
        <div className="shareItem">
          <div className="shareIcon bg-blue-500">&#xa0;</div>
        </div>
        <div className="shareItem">
          <div className="shareIcon bg-blue-500">&#xa0;</div>
        </div>
        <br clear="all"/>
      </div>

      <div class="trophyblock">
        Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </div>

    </div>
    </>
  );
}

export default Home;
