import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function handleNotImplemented() {
  alert("This feature is disabled at the moment");
}

function SignIn() {

  const query = useQuery();
  const error = query.get('err');
  const evt = query.get('evt');
  const verified = query.get('verified');
  const redirectTo = query.get('redirectTo');

  return (
    <>



    <link href="https://fonts.cdnfonts.com/css/sf-pro-display" rel="stylesheet"/>

      <div className="homeblock signinheaderblock text-center">
        <h1 className="signinheader">Sign In</h1>
      </div>

      {evt === 'dh18' && <div className="message" id="err">You have successfully signed up!<br/>Please check your email to verify your account.</div>}
      {verified && <div className="message">Your email has been successfully verified. You can now log in.</div>}


      <form action="/login/password" method="post">

      <input type="hidden" name="redirectTo" value={redirectTo} />

      <div className="homeblock text-center">
      <div className="signinblock">

          <section>
              <input className="inputFieldSignIn" id="username" name="username" type="text" autocomplete="username" placeholder="Email" required autofocus/>
          </section>
          <section>
              <input className="inputFieldSignIn" id="current-password" name="password" type="password" autocomplete="current-password" placeholder="Password" required/>
          </section>
        {error === 'af62' && <div className="error-message" id="err">Incorrect username or password.</div>}
      </div>

      <button className="signin-btn font-bold text-center bg-black" type="submit">Continue</button>

      </div>
      </form>


      <div className="homeblock socialloginblock text-center">
        <div className="sociallogintitle">
          Login with:
        </div>
        <div className="sociallogins">
        {/* Disable social logins for now
          <a href="/login/federated/google" className="signinball">
            <img src="/images/logo_google.png"/>
          </a>
        */}
          <a href="/login/federated/google" className="signinball">
            <img src="/images/logo_google.png"/>
          </a>
          {/*
            <a href="/login/federated/google" className="signinball">
              <img src="/images/logo_google.png"/>
            </a>
            <div className="signinball" onClick={handleNotImplemented}>
              <img src="/images/logo_fb.png"/>
            </div>
          */}
        </div>
      </div>
      <br/>
      <div className="homeblock text-center">
        <p>New user? <Link to={`/SignUp?redirectTo=${encodeURIComponent(redirectTo)}`} className="underline">Sign up here</Link></p>
      </div>



    </>

  );
}

export default SignIn;
