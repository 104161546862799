import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { UserContext } from './App';

function AdminHome() {
    const { user, setUser } = useContext(UserContext);
    const [teams, setTeams] = useState([]);
    const [ongoingEvents, setOngoingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [comingEvents, setComingEvents] = useState([]);

    const navigate = useNavigate();


    function handleNotImplemented(event) {
      event.preventDefault();
      alert("This feature is disabled at the moment");
    }


    useEffect(() => {
      


      // Fetching teams data
      if (user.userId) {

      fetch(`/api/listTeams/${user.userId}`)
        .then(response => response.json())
        .then(data => {
          const teamData = data.map(team => ({
            id: team.id,
            name: team.name,
            club_name: team.club_name,
          }));
          setTeams(teamData);
        });

      fetch(`/api/listComingEvents/${user.userId}`)
        .then(response => response.json())
        .then(data => setComingEvents(data));

      // Fetch ongoing and past events/events
      fetch(`/api/listOngoingEvents/${user.userId}`)
        .then(response => response.json())
        .then(data => setOngoingEvents(data));

      fetch(`/api/listPastEvents/${user.userId}`)
        .then(response => response.json())
        .then(data => setPastEvents(data));

      }
    }, [user.userId]);


    function formatDate(dateString) {
      return dateString.substring(0,10);
    }


  return (
    <div className="homeblock headertop relative">
      <Link to="/" className="homelink absolute text-black font-bold text-left hidden">/</Link>

      <div className="" id="user">
        <div id="userpic">
          {user.profilePicUrl ? (
            <img src={user.profilePicUrl} alt="User Profile" />
          ) : (
            <div>&#xa0;</div> // Placeholder if no picture is available
          )}
        </div>
        <div id="userinfowrapper">
          <div id="userinfo">
            <div id="usernameui">


            {user.userId && (
               <Link to={`/AdminUserProfile/${user.userId}`}>
                 {user.firstName} {user.lastName}
               </Link>
             )}


            </div>
            <div id="userlocation">{user.club}</div>
          </div>
          &#xa0;
        </div>
        <br clear="all"/>
      </div>

      <div className="vvblock text-center" id="admincontrols">
        <Link to="/AdminNewTeam" className="adminbtn">New Team</Link>
        <Link to="/AdminEnterEvent" className="adminbtn">Enter Event</Link>
        <Link to="/AdminNewEvent" className="adminbtn">New Event</Link>
      </div>

      <div className="vvblock" id="adminteams">

        <div className="vvblocktitle">My Teams</div>

        {teams.length > 0 ? (
          teams.map(team => (

            <Link to={`/AdminTeam/${team.id}`} className="team" key={team.id}>
              <div className="team-name"><b>{team.club_name}</b> : {team.name}</div>
            </Link>

          ))
        ) : (
          <div className="text-center">You have no Teams yet</div>
        )}
      </div>

      <div className="vvblock" id="adminevents">

        <div className="vvblocktitle">My Events</div>

        <div id="comingtitle" className="font-bold text-left">Coming events</div>
        {comingEvents.length > 0 ? (
          comingEvents.map(event => (
            <Link to={`/AdminEvent/${event.id}`} className="event" key={event.id}>
              <div className="eventDate">{formatDate(event.start_date)} - {formatDate(event.end_date)}</div> <div className="truncate"><b>{event.name}</b>
              {event.division_count > 0 && (
                <span>&#xa0;( {event.division_count} )</span>
              )}</div>
            </Link>
          ))
        ) : (
          <div className="text-center">You have no coming events</div>
        )}

        <div id="ongoingtitle" className="font-bold text-left mt-4">Ongoing events</div>
        {ongoingEvents.length > 0 ? (
          ongoingEvents.map(event => (
            <Link to={`/AdminEvent/${event.id}`} className="event" key={event.id}>
              <div className="eventDate">{formatDate(event.start_date)} - {formatDate(event.end_date)}</div> <div className="truncate"><b>{event.name}</b>
              {event.division_count > 0 && (
                <span>&#xa0;( {event.division_count} )</span>
              )}</div>
            </Link>
          ))
        ) : (
          <div className="text-center">You have no ongoing events</div>
        )}

        <div id="pasttitle" className="font-bold text-left mt-4">Past events</div>
        {pastEvents.length > 0 ? (
          pastEvents.map(event => (
            <Link to={`/AdminEvent/${event.id}`} className="event" key={event.id}>
              <div className="eventDate">{formatDate(event.start_date)} - {formatDate(event.end_date)}</div> <div className="truncate"><b>{event.name}</b>
              {event.division_count > 0 && (
                <span>&#xa0;( {event.division_count} )</span>
              )}</div>
            </Link>
        ))
        ) : (
          <div className="text-center">You have no past events</div>
        )}
      </div>
    </div>
  );
}

export default AdminHome;
