import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function Terms() {



  return (
    <div className="h-screen">

      <div className="vvblock" id="tandc">

      <div className="vvblocktitle">Terms and Conditions</div>

<h1 className="font-bold text-xl">Terms of Use for Consumers of YEESS</h1>
<br/>
<p>
  Effective Date: March 15, 2024
</p><br/>
<p>
  Welcome to YEESS! Our services, accessible at <a href="https://www.yeess.io">www.yeess.io</a> (&quot;Website&quot;), allow consumers (&quot;You&quot; or &quot;Your&quot;) to receive, store, and share digital medals and trophies awarded by businesses, sports teams, or organizations (&quot;Sponsors&quot;). These Terms of Use (&quot;Terms&quot;) govern Your use of our services. By accessing or using our services, You agree to be bound by these Terms and our Privacy Policy.
</p><br/>
<h2 className="font-bold">
  1. Account Registration
</h2><br/>
<p>
  To use our services, You must create an account with YEESS. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. YEESS reserves the right to suspend or terminate Your account if any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete.
</p><br/>
<h2 className="font-bold">
  2. Receiving and Storing Digital Medals and Trophies
</h2><br/>
<ul className="termsul">
  <li>
      <b>Receiving Medals/Trophies:</b>
      &nbsp;You may receive digital medals or trophies from Sponsors through our Website. Each digital medal or trophy will be added to Your personal account upon receipt.
  </li>
  <li>
      <b>Storage:</b>
      &nbsp;YEESS allows You to store received digital medals and trophies within Your account. We endeavor to ensure the security and accessibility of Your medals and trophies, but do not guarantee that storage will be free of disruptions or losses.
  </li>
</ul><br/>
<h2 className="font-bold">
  3. Sharing to Social Media
</h2><br/>
<p>
  YEESS grants You the capability to share images or information about Your digital medals or trophies on social media platforms such as Instagram, TikTok, and Facebook. When sharing content:
</p><br/>
<ul className="termsul">
  <li>
      <b>Permissions:</b>
      &nbsp;Ensure You have the right or permission to share any content from Your account to external platforms.
  </li>
  <li>
      <b>Compliance with Social Media Policies:</b>
      &nbsp;Your sharing of content must comply with the terms and conditions of the respective social media platforms.
  </li>
  <li>
      <b>No Misuse:</b>
      &nbsp;You agree not to use the sharing feature for any unlawful purpose or in any way that violates these Terms.
  </li>
</ul><br/>
<h2 className="font-bold">
  4. Intellectual Property
</h2><br/>
<p>
  All rights, title, and interest in and to the YEESS services and content (excluding Your digital medals and trophies), including but not limited to the Website design, text, graphics, and other files, are the proprietary property of NonFun Agency Ltd or its licensors. You may not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of our material, except as permitted by these Terms.
</p><br/>
<h2 className="font-bold">
  5. Privacy
</h2><br/>
<p>
  Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect Your personal information. By using our services, You agree to the terms of our Privacy Policy.
</p><br/>
<h2 className="font-bold">
  6. Limitation of Liability
</h2><br/>
<p>
  YEESS shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to, loss of profits, data, or other intangible losses, arising out of or in connection with Your use, or inability to use, our services.
</p><br/>
<h2 className="font-bold">
  7. Changes to Terms
</h2><br/>
<p>
  YEESS reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days&#39; notice prior to any new terms taking effect. By continuing to access or use our services after those revisions become effective, You agree to be bound by the revised terms.
</p><br/>
<h2 className="font-bold">
  8. Governing Law
</h2><br/>
<p>
  These Terms shall be governed and construed in accordance with the laws of Finland, without regard to its conflict of law provisions.
</p><br/>
<h2 className="font-bold">
  9. Contact Us
</h2><br/>
<p>
  If You have any questions about these Terms, please contact us at:
</p><br/>
<ul className="termsul">
  <li>
      Email: contact@nonfun.agency
  </li>
  <li>
      Mailing Address: NonFun Agency Ltd, Meritullinkatu 11 D, 00170 Helsinki, Finland
  </li>
</ul><br/>


      </div>
    </div>
  );
}

export default Terms;
