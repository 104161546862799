import { useState, useEffect, useContext } from 'react';
import { Link  } from 'react-router-dom';
import { UserContext } from './App';

function HeaderSignIn() {

  const { user, setUser } = useContext(UserContext);



  return (

    <>
    <link rel="preconnect" href="https://fonts.googleapis.com"></link>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="crossorigin"></link>
    <link href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700;900&display=swap" rel="stylesheet"></link>
    <link href="https://fonts.googleapis.com/css2?family=Bungee&display=swap" rel="stylesheet"></link>
    </>


  );
}

export default HeaderSignIn;
