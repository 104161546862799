import React, { useState } from 'react';

/*

// USER
{
  "success": true,
  "result": {
    "id": "61682cf0-aa58-45e1-b977-ad1a8a73c6e1",
    "reference": "Test User",
    "createdAt": "2024-01-26T13:37:19.371145227",
    "signingMethods": [
      {
        "id": "4073f77f-a8b3-4b35-9f9f-4288c34d6f70",
        "type": "PIN",
        "incorrectAttempts": 0,
        "remainingAttempts": 10,
        "hasMasterSecret": true
      }
    ]
  }
}

// WALLET
{
  "success": true,
  "result": {
    "id": "6b547815-9b62-43b3-bd82-4928cdcad179",
    "address": "0xEA14d0061674AbfAc0d1c0BB10F32793191F0EE2",
    "walletType": "API_WALLET",
    "secretType": "MATIC",
    "createdAt": "2024-01-27T07:00:38.964305334",
    "archived": false,
    "description": "yeess wallet",
    "primary": false,
    "hasCustomPin": false,
    "userId": "61682cf0-aa58-45e1-b977-ad1a8a73c6e1",
    "custodial": false,
    "balance": {
      "available": true,
      "secretType": "MATIC",
      "balance": 0,
      "gasBalance": 0,
      "symbol": "MATIC",
      "gasSymbol": "MATIC",
      "rawBalance": "0",
      "rawGasBalance": "0",
      "decimals": 18
    }
  }
}



*/

const TestUI = () => {
  const [responseTextVenly, setResponseTextVenly] = useState(''); // State to store response text
  const [responseTextThirdweb, setResponseTextThirdweb] = useState(''); // State to store response text

  const handleCreateVenlyUser = () => {
    setResponseTextVenly('Creating User...');
    // Make an API request to create a Venly User
    fetch('/api/create-venly-user', {
      method: 'POST', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleCreateVenlyWallet = () => {
    setResponseTextVenly('Creating Wallet...');
    // Make an API request to create a Venly Wallet
    fetch('/api/create-venly-wallet', {
      method: 'POST', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleCreateVenlyNFT = () => {

    /*

        {
      "name": "Yeess! test collection",
      "description": "Sample description",
      "confirmed": false,
      "id": 5461,
      "secretType": "MATIC",
      "symbol": "YETECO",
      "externalUrl": "https://www.yeess.io",
      "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65ae7c175f4fd11de8e8b4aa_logo1.png",
      "media": [],
      "transactionHash": "0xe6d7ee4c09f5fc87059cc4795ace9602f48a3b212276b7c3b6926833f0ca0783",
      "storage": {
        "type": "cloud",
        "location": "https://metadata.arkane.network/metadata/contracts/5461"
      },
      "external_link": "https://www.yeess.io"
    }
    */

    /*
    {
      "success": true,
      "result": {
        "name": "Yeess! test collection",
        "description": "Sample description",
        "id": "1d0b6ac1-4481-43bc-aa2c-637924fd33c1",
        "secretType": "MATIC",
        "symbol": "YETECO",
        "externalUrl": "https://www.yeess.io",
        "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
        "media": [],
        "transactionHash": "0x7cdb5272f07258e18c5f6babfb75ff93995252c8d3601796b8b90fe897a359e7",
        "status": "PENDING",
        "storage": {
          "type": "cloud",
          "location": "https://metadata.arkane.network/metadata/contracts/5548"
        },
        "contractUri": "https://metadata.arkane.network/metadata/contracts/5548",
        "external_link": "https://www.yeess.io"
      }
    }
    */


    setResponseTextVenly('Creating NFT Collection...');
    // Make an API request to create a Venly Wallet
    fetch('/api/create-venly-NFT', {
      method: 'POST', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleCreateVenlyNFTTemplate = () => {

    /*
    {
      "id": 1,
      "confirmed": false,
      "name": "Test NFT",
      "description": "Test template",
      "fungible": false,
      "burnable": false,
      "externalUrl": "https://www.yeess.io",
      "image": "https://app.yeess.io/images/collections/2/web/gold_f.png",
      "imageThumbnail": "https://app.yeess.io/images/collections/2/web/gold_f.png",
      "imagePreview": "https://app.yeess.io/images/collections/2/web/gold_f.png",
      "currentSupply": 0,
      "animationUrls": [],
      "attributes": [],
      "transactionHash": "0x5f96aa7e96888ba5a4768ba123c460fbca45b84e0ebcf64b45348d3278b5569a",
      "storage": {
        "type": "cloud",
        "location": "https://metadata.arkane.network/metadata/contracts/5461/tokens/{id}"
      }
    }
    */

    /*    {
      "success": true,
      "result": {
        "creations": [
          {
            "id": "5d92df0c-7eb7-486e-ba4e-1efa300876d7",
            "status": "PENDING",
            "tokenTypeId": 1,
            "metadata": {
              "name": "Yeess! NFT Test Token Type / Template",
              "description": "Yeess! NFT Test Token Type / Template",
              "image": "https://app.yeess.io/images/collections/2/web/gold_f.png",
              "imagePreview": "https://app.yeess.io/images/collections/2/web/gold_f.png",
              "imageThumbnail": "https://app.yeess.io/images/collections/2/web/gold_f.png",
              "animationUrls": [],
              "attributes": [
                {
                  "type": "system",
                  "name": "tokenTypeId",
                  "value": "1",
                  "traitType": "Token Type ID",
                  "trait_type": "Token Type ID"
                }
              ],
              "contract": {
                "address": "0x529e7878f31a937b96e19319cbbd24e3669d7cb5",
                "name": "Yeess! test collection",
                "symbol": "YETECO",
                "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
                "imageUrl": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
                "image_url": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
                "description": "Sample description",
                "externalLink": "https://www.yeess.io",
                "external_link": "https://www.yeess.io",
                "externalUrl": "https://www.yeess.io",
                "external_url": "https://www.yeess.io",
                "media": [],
                "type": "ERC_1155"
              },
              "fungible": false
            }
          }
        ]
      }
    }

    */
    setResponseTextVenly('Creating NFT Template...');
    // Make an API request to create a Venly Wallet
    fetch('/api/create-venly-NFT-template', {
      method: 'POST', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleMintVenlyNFT = () => {

    /*
    [
      {
        "transactionHash": "0x77b193b89f892c2a4cfbb6535063c9a4442f9ce8dfddbdaa1786a79deea87f7b",
        "metadata": {
          "name": "Test NFT",
          "description": "Test template",
          "image": "https://app.yeess.io/images/collections/2/web/gold_f.png",
          "imagePreview": "https://app.yeess.io/images/collections/2/web/gold_f.png",
          "imageThumbnail": "https://app.yeess.io/images/collections/2/web/gold_f.png",
          "externalUrl": "https://www.yeess.io",
          "external_url": "https://www.yeess.io",
          "animationUrls": [],
          "attributes": [
            {
          "type": "system",
          "name": "tokenTypeId",
          "value": "1",
          "traitType": "Token Type ID",
          "trait_type": "Token Type ID"
        }
      ],
      "contract": {
        "address": "0x8521a6a918076817d7e88c8f79473a36beb0e096",
        "name": "Yeess! test collection",
        "symbol": "YETECO",
        "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65ae7c175f4fd11de8e8b4aa_logo1.png",
        "imageUrl": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65ae7c175f4fd11de8e8b4aa_logo1.png",
        "image_url": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65ae7c175f4fd11de8e8b4aa_logo1.png",
        "description": "Sample description",
        "externalLink": "https://www.yeess.io",
        "external_link": "https://www.yeess.io",
        "externalUrl": "https://www.yeess.io",
        "external_url": "https://www.yeess.io",
        "media": [],
        "type": "ERC_1155"
      },
      "fungible": false
    },
    "destinations": [
      "0xEA14d0061674AbfAc0d1c0BB10F32793191F0EE2"
    ],
    "tokenIds": [
      2
    ],
    "mintedTokens": [
      {
        "destination": "0xEA14d0061674AbfAc0d1c0BB10F32793191F0EE2",
        "tokenId": 2,
        "txHash": "0x77b193b89f892c2a4cfbb6535063c9a4442f9ce8dfddbdaa1786a79deea87f7b"
      }
    ]
  }
]

*/
/*
{
  "success": true,
  "result": {
    "mints": [
      {
        "id": "fe8b678e-f3b8-4346-aec1-aeace45b794e",
        "createdOn": "2024-03-27T10:58:09.308273581",
        "status": "PENDING",
        "destination": {
          "address": "0xEA14d0061674AbfAc0d1c0BB10F32793191F0EE2",
          "amount": 1
        }
      }
    ],
    "metadata": {
      "name": "Yeess! NFT Test Token Type / Template",
      "description": "Yeess! NFT Test Token Type / Template",
      "image": "https://app.yeess.io/images/collections/2/web/gold_f.png",
      "imagePreview": "https://app.yeess.io/images/collections/2/web/gold_f.png",
      "imageThumbnail": "https://app.yeess.io/images/collections/2/web/gold_f.png",
      "animationUrls": [],
      "attributes": [
        {
          "type": "system",
          "name": "tokenTypeId",
          "value": "1",
          "traitType": "Token Type ID",
          "trait_type": "Token Type ID"
        }
      ],
      "contract": {
        "address": "0x529e7878f31a937b96e19319cbbd24e3669d7cb5",
        "name": "Yeess! test collection",
        "symbol": "YETECO",
        "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
        "imageUrl": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
        "image_url": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
        "description": "Sample description",
        "externalLink": "https://www.yeess.io",
        "external_link": "https://www.yeess.io",
        "externalUrl": "https://www.yeess.io",
        "external_url": "https://www.yeess.io",
        "media": [],
        "type": "ERC_1155"
      },
      "fungible": false
    }
  }
}

*/
    setResponseTextVenly('Minting NFT...');
    // Make an API request to create a Venly Wallet
    fetch('/api/mint-venly-NFT', {
      method: 'POST', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleListVenlyNFTTemplates = () => {
    setResponseTextVenly('Listing NFT Templates...');
    // Make an API request to create a Venly Wallet
    fetch('/api/list-venly-NFT-templates', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleCheckVenlyNFTTemplate = () => {

    /*
    {
      "success": true,
      "result": {
        "id": "5d92df0c-7eb7-486e-ba4e-1efa300876d7",
        "status": "SUCCEEDED",
        "transactionHash": "0x6d590f40fc16f909a59bdb1c13eb455a84c11c73c815044f2d05cdd81baf1ce2",
        "tokenTypeId": 1,
        "mints": [],
        "metadata": {
          "name": "Yeess! NFT Test Token Type / Template",
          "description": "Yeess! NFT Test Token Type / Template",
          "image": "https://app.yeess.io/images/collections/2/web/gold_f.png",
          "imagePreview": "https://app.yeess.io/images/collections/2/web/gold_f.png",
          "imageThumbnail": "https://app.yeess.io/images/collections/2/web/gold_f.png",
          "animationUrls": [],
          "attributes": [
            {
              "type": "system",
              "name": "tokenTypeId",
              "value": "1",
              "traitType": "Token Type ID",
              "trait_type": "Token Type ID"
            }
          ],
          "contract": {
            "address": "0x529e7878f31a937b96e19319cbbd24e3669d7cb5",
            "name": "Yeess! test collection",
            "symbol": "YETECO",
            "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
            "imageUrl": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
            "image_url": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
            "description": "Sample description",
            "externalLink": "https://www.yeess.io",
            "external_link": "https://www.yeess.io",
            "externalUrl": "https://www.yeess.io",
            "external_url": "https://www.yeess.io",
            "media": [],
            "type": "ERC_1155"
          },
          "fungible": false
        }
      }
    }
    */


    setResponseTextVenly('Checking NFT Template...');
    // Make an API request to create a Venly Wallet
    fetch('/api/check-venly-NFT-template', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };



  const handleListVenlyGasFees = () => {
    setResponseTextVenly('Listing Gas Fees...');
    // Make an API request to create a Venly Wallet
    fetch('/api/list-venly-gas-fees', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };


  const handleListVenlyNFTs = () => {
    setResponseTextVenly('Listing NFT Collections...');
    // Make an API request to create a Venly Wallet
    fetch('/api/list-venly-NFTs', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleCheckVenlyNFT = () => {

    /*
    {
      "success": true,
      "result": {
        "name": "Yeess! test collection",
        "description": "Sample description",
        "address": "0x529e7878f31a937b96e19319cbbd24e3669d7cb5",
        "id": "1d0b6ac1-4481-43bc-aa2c-637924fd33c1",
        "secretType": "MATIC",
        "symbol": "YETECO",
        "externalUrl": "https://www.yeess.io",
        "image": "https://assets-global.website-files.com/659e545a2bc40668a511a178/65b786c404a0e439053fc770_Asset%201.png",
        "media": [],
        "transactionHash": "0x7cdb5272f07258e18c5f6babfb75ff93995252c8d3601796b8b90fe897a359e7",
        "status": "SUCCEEDED",
        "storage": {
          "type": "cloud",
          "location": "https://metadata.arkane.network/metadata/contracts/5548"
        },
        "contractUri": "https://metadata.arkane.network/metadata/contracts/5548",
        "external_link": "https://www.yeess.io"
      }
    }
    */


    setResponseTextVenly('Checking NFT Collection...');
    // Make an API request to create a Venly Wallet
    fetch('/api/check-venly-NFT', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleListVenlyWallets = () => {
    setResponseTextVenly('Listing Wallets...');
    // Make an API request to create a Venly Wallet
    fetch('/api/list-venly-wallets', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleListVenlyNFTsInWallet = () => {
    setResponseTextVenly('Listing NFTs in a Wallet...');
    // Make an API request to create a Venly Wallet
    fetch('/api/list-venly-NFTs-in-wallet', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };

  const handleCheckVenlyNFTMint = () => {
    setResponseTextVenly('Checking NFT mint...');
    // Make an API request to create a Venly Wallet
    fetch('/api/check-venly-NFT-mint', {
      method: 'GET', // Use the appropriate HTTP method (GET, POST, etc.)
      // Add headers and body data if needed
    })
    .then((result) => result.json())
    .then((data) => {
      const formattedData = JSON.stringify(data, null, 2); // Formatting with 2 spaces
      setResponseTextVenly(formattedData);
    })
    .catch((error) => {
      // Handle network or other errors
      console.error(error);
    });
  };






  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col venlybuttons justify-center items-center z-10">
      <div>
        <button
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-1 rounded-l"
          onClick={handleCreateVenlyUser}
        >
        Create User
      </button>
        <button
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-1 rounded-l"
          onClick={handleCreateVenlyWallet}
        >
          Create Wallet
        </button>
        <button
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleCreateVenlyNFT}
        >
          Create NFT Collection
        </button>
        <button
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleCreateVenlyNFTTemplate}
        >
          Create NFT Template
        </button>
        <button
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleMintVenlyNFT}
        >
          Mint NFT
        </button>
        </div>
        <div className="mt-4">

        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleListVenlyGasFees}
        >
          List Gas Fees
        </button>
        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleListVenlyWallets}
        >
          List Wallets
        </button>

        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleListVenlyNFTs}
        >
          List NFT Collections
        </button>
        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleCheckVenlyNFT}
        >
          Check NFT Collection
        </button>
        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleListVenlyNFTTemplates}
        >
          List NFT Templates
        </button>
        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleCheckVenlyNFTTemplate}
        >
          Check NFT Template
        </button>
        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleListVenlyNFTsInWallet}
        >
          List NFTs in Wallet
        </button>
        <button
          className="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mx-1 rounded-r"
          onClick={handleCheckVenlyNFTMint}
        >
          Check NFT Mint
        </button>
        </div>

      </div>


      <div className="respTextVenly text-blue-600 z-0">
        <pre>{responseTextVenly}</pre>
      </div>
      <div className="respTextThirdweb text-green-600">
        <pre>{responseTextThirdweb}</pre>
      </div>
    </div>
  );
};


export default TestUI;
