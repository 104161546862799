import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './App';


function HeaderNoHeader() {

  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();




  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('/api/current_user', {
          credentials: 'include' // Important for cookies to be sent and received
        });
        const data = await response.json();
        if (data.user) {

          //
        } else {
          console.log('User not authenticated, redirecting...');
          const currentPath = encodeURIComponent(location.pathname);
          navigate(`/SignIn?redirectTo=${currentPath}`);

        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [navigate]);

  function handleBack() {
      navigate(-1); // Goes back to the previous page
  }

  function handleHome() {
      navigate('/'); // Goes home
  }



  return (

    <>
    <link rel="preconnect" href="https://fonts.googleapis.com"></link>
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="crossorigin"></link>
    <link href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700;900&display=swap" rel="stylesheet"></link>
    <link href="https://fonts.googleapis.com/css2?family=Bungee&display=swap" rel="stylesheet"></link>


    <a className="logout" href="/login/logout">
      <img src="/images/logout.png"/>
    </a>
    <Link to={`/UserProfile/${user.userId}`} className="settings">
      <img src="/images/settings.png"/>
    </Link>
    <div className="back_home">
      <img onClick={handleBack} className="backimg" src="/images/back.png"/>
      <img onClick={handleHome} className="homeimg" src="/images/home.png"/>
    </div>
    </>


  );
}

export default HeaderNoHeader;
