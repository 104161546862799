import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './App';


function UserHeader() {

  const navigate = useNavigate();
  const location = useLocation();

  const { user, setUser } = useContext(UserContext);



  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('/api/current_user', {
          credentials: 'include'
        });
        const data = await response.json();
        if (data.user) {
          // Fetch the profile picture URL from a separate API endpoint
          let profilePicData = null;

          const profilePicResponse = await fetch(`/api/getUserProfile/${data.user.id}`, {
              credentials: 'include'
          });
          profilePicData = await profilePicResponse.json();

          console.info("PROFILE", profilePicData);


          let profilePicUrl = '';

          if(Array.isArray(profilePicData) && profilePicData.length > 0) {
            profilePicUrl = profilePicData[0].profile_picture_url;
          }

          setUser({
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            userId: data.user.id,
            profilePicUrl: profilePicUrl,
            gender: data.user.gender
          });



        } else {
          console.log('User not authenticated, redirecting...');
          const currentPath = encodeURIComponent(location.pathname);
          if(location.pathname.startsWith('/UserJoinTeam')) {
            navigate(`/SignUp?redirectTo=${currentPath}`);
          } else {
            navigate(`/SignIn?redirectTo=${currentPath}`);
          }


        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };


    fetchUser();
  }, [navigate]);


  function handleBack() {
      navigate(-1); // Goes back to the previous page
  }

  function handleHome() {
      navigate('/'); // Goes home
  }



  return (
      <>
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="crossorigin"></link>
      <link href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700;900&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Bungee&display=swap" rel="stylesheet"></link>
      <a className="logout" href="/login/logout">
        <img src="/images/logout.png"/>
      </a>
      <Link to={`/UserProfile/${user.userId}`} className="settings">
        <img src="/images/settings.png"/>
      </Link>
      <div className="back_home">
        <img onClick={handleBack} className="backimg" src="/images/back.png"/>
        <img onClick={handleHome} className="homeimg" src="/images/home.png"/>
      </div>
      <div className="homeblock headertop relative">
        <Link to="/" className="homelink absolute text-black font-bold text-left hidden">/</Link>

        <div className="" id="user">
          <div id="userpic">
            {user.profilePicUrl ? (
              <img src={user.profilePicUrl} alt="User Profile" />
            ) : (
              <div>&#xa0;</div> // Placeholder if no picture is available
            )}
          </div>
          <div id="userinfowrapper">
            <div id="userinfo">
              <div id="usernameui">
              {user.userId && (
                 <Link to={`/UserProfile/${user.userId}`}>
                   {user.firstName} {user.lastName}
                 </Link>
               )}
              </div>
              <div id="userlocation">&#xa0;</div>
            </div>
            &#xa0;
          </div>
          <br clear="all"/>
        </div>
      </div>
      </>
  );
}

export default UserHeader;
