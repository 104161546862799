import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from './App';


function AdminEnterEvent() {
  const [events, setEvents] = useState([]); // Events will be an array of event objects
  const [selectedEventId, setSelectedEventId] = useState(''); // Tracks the selected event ID
  const [selectedTeam, setSelectedTeam] = useState('');
  const [divisions, setDivisions] = useState([]); // Divisions related to the selected event
  const [selectedDivisionId, setSelectedDivisionId] = useState('');
  const [teams, setTeams] = useState([]); // Add this line to create a state for the teams
  const [teamsAlreadyPartOfEvent, setTeamsAlreadyPartOfEvent] = useState([]); // Add this line to create a state for the teams
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { user, setUser } = useContext(UserContext);


  const navigate = useNavigate();
  //const userId = user.id; // Replace with the actual user ID, could be from props or context

  useEffect(() => {

    if(selectedEventId != '') {
    // Fetch the teams for the specific user
    fetch(`/api/listTeamsNotPartOfEvent/${user.userId}/${selectedEventId}`)
      .then(response => response.json())
      .then(data => {
        setTeams(data); // Assuming the API returns an array of team objects
      })
      .catch(error => console.error('Error fetching teams:', error));


    fetch(`/api/listTeamsAlreadyPartOfEvent/${user.userId}/${selectedEventId}`)
      .then(response => response.json())
      .then(data => {
        setTeamsAlreadyPartOfEvent(data); // Assuming the API returns an array of team objects
      })
      .catch(error => console.error('Error fetching teams:', error));
    }
  }, [selectedEventId, selectedTeam]);

  useEffect(() => {

    if(user.userId) {
      fetch(`/api/listComingAndOngoingEvents/${user.userId}`)
        .then(response => response.json())
        .then(data => setEvents(data))
        .catch(error => console.error('Error fetching events:', error));
      }
  }, [user.userId]);

  const handleEventSelection = (event) => {
    const eventId = event.target.value;
    setSelectedEventId(eventId);

    // Check if an event has been selected
    if (eventId) {
      // Fetch its divisions based on the selected event
      fetch(`/api/getDivisionsForEvent/${eventId}`)
        .then(response => response.json())
        .then(data => {
          setDivisions(data);
          // Reset selected division ID because previous selections are no longer valid
          setSelectedDivisionId('');
        })
        .catch(error => console.error('Error fetching divisions:', error));
    } else {
      // If no event is selected, clear the divisions
      setDivisions([]);
      setSelectedDivisionId('');
    }
  };

  const handleTeamSelection = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleDivisionSelection = (event) => {
    setSelectedDivisionId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedEventId || !selectedTeam || !selectedDivisionId) {
      alert("Please select an event, a team, and a division.");
      return;
    }

    const requestBody = {
      eventId: selectedEventId,
      teamId: selectedTeam,
      divisionId: selectedDivisionId,
    };

    fetch('/api/teamJoinEvent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(() => {
      const teamName = teams.find(team => team.id.toString() === selectedTeam)?.name;
      const eventName = events.find(event => event.id.toString() === selectedEventId)?.name;
      const divisionName = divisions.find(division => division.id.toString() === selectedDivisionId)?.name;

      setShowModal(true);
      setSuccessMessage(`Team ${teamName} has successfully joined the event "${eventName}" in the "${divisionName}" division.`);

      // Reset form fields and selections
      //setSelectedEventId('');
      setSelectedTeam('');
      setSelectedDivisionId('');
      //setDivisions([]);
    })
    .catch(error => {
      console.error('Error joining the team to the event:', error);
    });
  };


  return (
    <div className="h-screen">
      <div className="homeblock">
        {/* Form block for entering event details */}
        <div className="vvblock" id="enterEventControls">
          <h1 className="vvblocktitle">Enter Event</h1>
          <form onSubmit={handleSubmit} className="my-4">
              <label htmlFor="event-name">Event Name</label>
              <select id="event-name" value={selectedEventId} onChange={handleEventSelection} className="inputFieldGeneral">
                <option value="">Select an event</option>
                {events.map(event => (
                  <option key={event.id} value={event.id}>{event.name}</option>
                ))}
              </select>

              <div className="mt-1">
                <p className="font-bold">What team are you joining with?</p>
                <br/>
                {teams.length > 0 || selectedEventId === '' ? (
                  teams.map((team, index) => (
                    <label key={team.id} htmlFor={`team${team.id}`} className="flex items-center">
                      <input
                        type="radio"
                        id={`team${team.id}`}
                        name="team"
                        value={team.id}
                        onChange={handleTeamSelection}
                        checked={selectedTeam === team.id.toString()}
                        className="mr-2"
                      />
                      {team.club_name} - {team.name}
                    </label>
                  ))
                ) : (
                  <p className="text-green-700">All your teams already take part in this event<br/></p>

                )}
              </div>

              <div className="my-4">
                <label className="font-bold" htmlFor="division">What division are you joining in?</label>
                <select id="division" value={selectedDivisionId} onChange={handleDivisionSelection} className="inputFieldGeneral">
                  <option value="">
                    {!selectedEventId ? 'First, choose an event' : divisions.length === 0 ? 'No divisions for this event' : `Select a division for ${events.find(event => String(event.id) === selectedEventId)?.name}`}
                  </option>
                  {divisions.map(division => (
                    <option key={division.id} value={division.id}>{division.name}</option>
                  ))}
                </select>


              </div>


            <button type="submit" className="big-btn">
              Submit
            </button>
          </form>

          <div className="mt-1">
          {teamsAlreadyPartOfEvent.length > 0 && selectedEventId !== '' ? (
            <>
              <p className="font-bold pt-3 text-green-700">Your teams already taking part in this event:</p>
              <br />
              {teamsAlreadyPartOfEvent.map((team2, index) => (
                <div className="text-green-700" key={index}>{team2.club_name} - {team2.name}</div>
              ))}
            </>
          ) : (
            <p>No teams yet in this event</p>
          )}
          </div>
          {/* Modal for success message */}
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                <div className="generalmodaltitle">SUCCESS!</div>
                <br/>
                <p>{successMessage}</p>
                <br/>
                <button className="big-btn" onClick={() => setShowModal(false)}>Ok</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

}

export default AdminEnterEvent;
