import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from 'react';
import { UserContext } from './App';

function AdminNewEvent() {
  const { user, setUser } = useContext(UserContext);
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [comingEvents, setComingEvents] = useState([]);
  const [newEventName, setNewEventName] = useState('');
  const [newEventDescription, setNewEventDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null); // state to store the actual file
  const [isSaving, setIsSaving] = useState(false);


  const [showDivisions, setShowDivisions] = useState(false); // State to track checkbox
  const [divisions, setDivisions] = useState([]);
  const [divisionInputs, setDivisionInputs] = useState([
    { name: '', description: '', tiers1: 0, tiers2: 0, tiers3: 0, tiers4: 0, tiers5: 0 },
  ]);

  const navigate = useNavigate();  // Initialize the useNavigate hook

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(URL.createObjectURL(img));
      setImageFile(img);

    }
  };


  const handleCheckboxChange = (e) => {
    setShowDivisions(e.target.checked);
  };

  const handleAddDivision = () => {
    setDivisionInputs([...divisionInputs, { name: '', description: '', tiers1: 0, tiers2: 0, tiers3: 0, tiers4: 0, tiers5: 0 }]);
  };

  const handleDivisionInputChange = (index, e) => {
  const updatedDivisions = [...divisionInputs];
    updatedDivisions[index][e.target.name] =
      e.target.name === "tiers1" ||
      e.target.name === "tiers2" ||
      e.target.name === "tiers3" ||
      e.target.name === "tiers4" ||
      e.target.name === "tiers5"
        ? parseInt(e.target.value) // Parse the value to an integer for tier fields
        : e.target.value;
    setDivisionInputs(updatedDivisions);
  };



  function fetchEvents() {
    // Fetch ongoing and past events/events
    fetch(`/api/listComingEvents/${user.userId}`)
      .then(response => response.json())
      .then(data => setComingEvents(data));

    fetch(`/api/listOngoingEvents/${user.userId}`)
      .then(response => response.json())
      .then(data => setOngoingEvents(data));

    fetch(`/api/listPastEvents/${user.userId}`)
      .then(response => response.json())
      .then(data => setPastEvents(data));
  }

  useEffect(() => {



    fetchEvents();



  }, []);

  function formatDate(dateString) {
    return dateString.substring(0,10);
  }

  const handleSaveEvent = () => {
  if (newEventName && startDate && endDate) {

    const allDivisionsHaveNames = divisionInputs.every(division => division.name.trim() !== '');

    if (!allDivisionsHaveNames) {
      alert('Please fill out the name for every division.');
      return; // Stop the function if any division name is missing
    }

    setIsSaving(true); // Start saving process

    // Create a FormData object
    const formData = new FormData();
    formData.append('userId', user.userId);
    formData.append('eventName', newEventName);
    formData.append('eventDescription', newEventDescription);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('hasMultipleDivisions', showDivisions);
    formData.append('divisions', JSON.stringify(divisionInputs)); // Convert array to JSON string

    // Add the image file if it exists
    if (imageFile) {
      formData.append('image', imageFile);
    }

    // Send a POST request with FormData
    fetch('/api/addEvent', {
      method: 'POST',
      body: formData, // Use FormData object
      // Don't set Content-Type header, the browser will set it with the correct boundary
    })
    .then(response => response.json())
    .then(data => {
      console.info(data);
      setIsSaving(false); // Saving process finished

      navigate(`/AdminSelectTrophyStyle`, { state: { eventId: data.id } });
    })
    .catch(error => {
      setIsSaving(false); // Saving process finished

      console.error('Error saving the new event:', error);
    });
  } else {
    alert('Please fill at least event name + start and end dates before saving.');
  }
};


  return (
    <div className="h-screen">


      <div class="eventprofilepic">
        <input type="file" onChange={handleImageChange} style={{ display: 'none' }} id="fileInput" />
        <div className="uploaddisc"><img src="/images/uploadsign.png" onClick={() => document.getElementById('fileInput').click()}/></div>
        <img
          src={selectedImage ? selectedImage : '/images/eventbg.png'}
          alt="Event"
          className="selected-image-style"
        />

      </div>
      <div className="vvblock" id="neweventcontrols">
        <div className="text-xl font-bold hidden">CREATE NEW EVENT</div>
        <input
          type="text"
          placeholder="Event Name"
          className="inputFieldGeneral"
          value={newEventName}
          onChange={e => setNewEventName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Event Description"
          className="inputFieldGeneral newEventDate"
          value={newEventDescription}
          onChange={e => setNewEventDescription(e.target.value)}
        />
        <input
          type="date"
          placeholder="Start&#xa0;Date"
          className="inputFieldGeneral newEventDate"
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
        />
        <input
          type="date"
          placeholder="End&#xa0;Date"
          className="inputFieldGeneral"
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
        />
        <br/><br/>
        <div className="font-bold text-l">Divisions</div>
        <div className="text-right hidden">
          <label htmlFor="showDivisions">Multiple divisions</label>&#xa0;
          <input
            id="showDivisions"
            type="checkbox"
            checked={showDivisions}
            onChange={handleCheckboxChange}
          />
        </div>
        <br />
        {1 == 1 && (
          <>
            {divisionInputs.map((division, index) => (
              <div className="division" key={index}>
                <input
                  className="inputFieldGeneral"
                  type="text"
                  name="name"
                  placeholder="Division Name"
                  value={division.name}
                  onChange={(e) => handleDivisionInputChange(index, e)}
                />
                <textarea
                  className="inputField hidden"
                  name="description"
                  placeholder="Division Description"
                  value={division.description}
                  onChange={(e) => handleDivisionInputChange(index, e)}
                />
                <div id="divisiontierstitle" className="font-bold text-l mb-2">Division Tiers</div>
                <div className="divisiontiers">
                  <div className="tiershalf-1 float-left">
                    <div>
                    <div className={`tierball ${division.tiers1 > 0 ? 'green-disc' : ''}`}>&#xa0;</div>
                    <label for="tiers1">Gold</label>&#xa0;<input
                      className="inputFieldTier"
                      type="number"
                      id="tiers1"
                      name="tiers1"
                      placeholder="-"
                      value={division.tiers1}
                      onChange={(e) => handleDivisionInputChange(index, e)}
                    />
                    </div>
                    <br clear="all"/>
                    <div>
                    <div className={`tierball ${division.tiers3 > 0 ? 'green-disc' : ''}`}>&#xa0;</div>
                    <label for="tiers3">Bronze</label>&#xa0;<input
                      className="inputFieldTier"
                      type="number"
                      id="tiers3"
                      name="tiers3"
                      placeholder="-"
                      value={division.tiers3}
                      onChange={(e) => handleDivisionInputChange(index, e)}
                    />
                    </div>
                    <br clear="all"/>
                    <div>
                    <div className={`tierball ${division.tiers4 > 0 ? 'green-disc' : ''}`}>&#xa0;</div>
                    <label for="tiers4">Participation</label>&#xa0;<input
                      className="inputFieldTier"
                      type="number"
                      id="tiers4"
                      name="tiers4"
                      placeholder="-"
                      value={division.tiers4}
                      onChange={(e) => handleDivisionInputChange(index, e)}
                    />
                    </div>
                  </div>
                  <div className="tiershalf-2 float-right">
                    <div>
                    <div className={`tierball ${division.tiers2 > 0 ? 'green-disc' : ''}`}>&#xa0;</div>
                    <label for="tiers2">Silver</label>&#xa0;<input
                      className="inputFieldTier"
                      type="number"
                      id="tiers2"
                      name="tiers2"
                      placeholder="-"
                      value={division.tiers2}
                      onChange={(e) => handleDivisionInputChange(index, e)}
                    />
                    </div>
                    <br clear="all"/>
                    <div>
                    <div className={`tierball ${division.tiers5 > 0 ? 'green-disc' : ''}`}>&#xa0;</div>
                    <label for="tiers5">MVP</label>&#xa0;<input
                      className="inputFieldTier"
                      type="number"
                      id="tiers5"
                      name="tiers5"
                      placeholder="-"
                      value={division.tiers5}
                      onChange={(e) => handleDivisionInputChange(index, e)}
                    />
                    </div>
                    <br clear="all"/>
                  </div>
                  <br clear="all"/>
                </div>
              </div>
            ))}
            <br clear="all"/>
            <div className="adddivision" onClick={handleAddDivision}>
            <img src="/images/adddivision.png"/>
            <div className="float-left">Add Division</div>
            </div>
            <br clear="all"/><br />
          </>
        )}
        <br/>
        <button
          className="big-btn"
          onClick={handleSaveEvent}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </button>
      </div>

      <div className="homeblock hidden" id="adminevents">

        <div id="comingtitle" className="text-xl font-bold text-left">Coming events</div>
        {comingEvents.length > 0 ? (
          comingEvents.map(event => (
            <Link to={`/AdminEvent/${event.id}`} className="event" key={event.id}>
              <div className="eventDate">{formatDate(event.start_date)} - {formatDate(event.end_date)}</div> : <b>{event.name}</b>
              {event.division_count > 0 && (
                <span>&#xa0;{event.division_count} divisions</span>
              )}
            </Link>
          ))
        ) : (
          <div className="text-center">You have no coming events</div>
        )}

        <div id="ongoingtitle" className="text-xl font-bold text-left mt-4">Ongoing events</div>
        {ongoingEvents.length > 0 ? (
          ongoingEvents.map(event => (
            <Link to={`/AdminEvent/${event.id}`} className="event" key={event.id}>
              <div className="eventDate">{formatDate(event.start_date)} - {formatDate(event.end_date)}</div> : <b>{event.name}</b>
              {event.division_count > 0 && (
                <span>&#xa0;{event.division_count} divisions</span>
              )}
            </Link>
          ))
        ) : (
          <div className="text-center">You have no ongoing events</div>
        )}

        <div id="pasttitle" className="text-xl font-bold text-left mt-4">Past events</div>
        {pastEvents.length > 0 ? (
          pastEvents.map(event => (
            <Link to={`/AdminEvent/${event.id}`} className="event" key={event.id}>
              <div className="eventDate">{formatDate(event.start_date)} - {formatDate(event.end_date)}</div> : <b>{event.name}</b>
              {event.division_count > 0 && (
                <span>&#xa0;{event.division_count} divisions</span>
              )}
            </Link>
        ))
        ) : (
          <div className="text-center">You have no past events</div>
        )}
      </div>
    </div>
  );
}

export default AdminNewEvent;
