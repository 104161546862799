import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

function AdminDistributeTrophies() {
  let { eventId, divisionId } = useParams();
  const [division, setDivision] = useState(null);
  const [eventData, setEventData] = useState(null);  // Added state for event data
  const [users, setUsers] = useState([]);
  const [team, setTeam] = useState(null); // Added state for team data
  const [teams, setTeams] = useState([]); // State to hold list of teams
  const [selections, setSelections] = useState({}); // To keep track of selected tiers for each user
  const [availableTrophies, setAvailableTrophies] = useState({
   tiers1: 0, // Gold
   tiers2: 0, // Silver
   tiers3: 0, // Bronze
   tiers4: 0, // Participation
   tiers5: 0, // MVP
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [teamTierSelection, setTeamTierSelection] = useState("");

  function getTrophyName(trophyName) {
    const nameMap = {
      'tiers1': 'Gold',
      'tiers2': 'Silver',
      'tiers3': 'Bronze',
      'tiers4': 'Participation',
      'tiers5': 'MVP'
    };

    return nameMap[trophyName] || trophyName; // default to trophyName if not found in the map
  }

  function getDivision() {
    // Fetch division data
    axios.get(`/api/getDivision/${divisionId}`)
      .then(response => {
        setDivision(response.data);
        setAvailableTrophies({
          tiers1: response.data.tiers1,
          tiers2: response.data.tiers2,
          tiers3: response.data.tiers3,
          tiers4: response.data.tiers4,
          tiers5: response.data.tiers5,
        });
      })
      .catch(error => {
        console.error('There was an error fetching the division data!', error);
      });
  }

  useEffect(() => {

    // Fetch team data (using hardcoded id=8 for development)
    axios.get(`/api/getTeamsForEventAndDivision/${eventId}/${divisionId}`)
      .then(response => {
        setTeams(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the teams data!', error);
      });

    // Fetch event data
    axios.get(`/api/getEvent/${eventId}`)
      .then(response => {
        setEventData(response.data.event);
      })
      .catch(error => {
        console.error('There was an error fetching the event data!', error);
      });


      getDivision();


      // Fetch users in this division
      if(team) {
        axios.get(`/api/listDivisionUsers/${divisionId}/${team.id}`)
          .then(response => {
            setUsers(response.data);
            // Initialize selections state
            const initialSelections = response.data.reduce((acc, user) => {
              acc[user.id] = ""; // Initialize with no tier selected
              return acc;
            }, {});
            setSelections(initialSelections);
          })
          .catch(error => {
            console.error('There was an error fetching the users!', error);
          });
      }
    }, [eventId, divisionId, team]);

    const handleTierChange = useCallback((userId, tier) => {
      setSelections(prevSelections => {
        const prevTier = prevSelections[userId];
        let newAvailableTrophies = { ...availableTrophies };

        // If changing the selection, increment the previously selected tier
        if (prevTier && newAvailableTrophies[prevTier] !== undefined) {
          newAvailableTrophies[prevTier]++;
        }
        // Then decrement the newly selected tier if available
        if (tier && newAvailableTrophies[tier] > 0) {
          newAvailableTrophies[tier]--;
        } else if (tier) {
          // If there are no trophies available, do not allow the change
          return prevSelections;
        }

        setAvailableTrophies(newAvailableTrophies);
        return {
          ...prevSelections,
          [userId]: tier,
        };
      });
    }, [availableTrophies]);

    const handleTeamTierChange = (tier) => {
      const totalNeededTrophies = users.length; // Total trophies needed for the whole team
      const availableTrophiesForTier = availableTrophies[tier]; // Available trophies for the selected tier

      if (availableTrophiesForTier < totalNeededTrophies) {
        alert(`Not enough ${getTrophyName(tier)} trophies available for the whole team.`);
        setTeamTierSelection(""); // Reset team tier selection
        return; // Stop further execution
      }

      setTeamTierSelection(tier);

      // Update selections and available trophies
      const updatedSelections = { ...selections };
      const updatedAvailableTrophies = { ...availableTrophies };

      users.forEach(user => {
        updatedSelections[user.id] = tier;
        updatedAvailableTrophies[tier]--; // Decrement the available trophy count for the selected tier
      });

      setSelections(updatedSelections);
      setAvailableTrophies(updatedAvailableTrophies);
    };



    const handleDistribute = () => {
      // Prepare the payload with eventId and the selections
      const payload = {
        eventId: eventId, // Add the eventId to the payload
        divisionId: divisionId,
        selections: selections
      };

      // Send the POST request with the payload
      axios.post(`/api/distributeTrophies`, payload)
        .then(response => {
          console.log('Trophies have been distributed', response.data);
          getDivision();
          setShowSuccessDialog(true); // Show the success dialog

          // Handle success (e.g., show a success message or redirect)
        })
        .catch(error => {
          console.error('There was an error distributing the trophies!', error);
          // Handle error (e.g., show an error message)
        });
    };

  const closeDialog = () => {
    setShowSuccessDialog(false);
  };


  return (
    <>
    <div className="h-screen">


    <div className="homeblock headertop relative">
      <Link to="/" className="homelink absolute text-black font-bold text-left hidden">/</Link>
      <div className="logout hidden"><a href="/login/logout">logout</a></div>
      <div className="" id="user">
        <div id="userpic">
          {eventData?.profile_pic_url ? (
            <img src={eventData.profile_pic_url} alt="Event Profile" />
          ) : (
            <img src="/images/eventbg.png" alt="Event Profile" />
          )}
        </div>
        <div id="userinfowrapper">
          <div id="userinfo">
            <div id="usernameui">
            {eventData?.name || 'Loading event...'}
            </div>
            <div>
            {eventData?.start_date && eventData?.end_date && (
              <span>
                {eventData.start_date.substring(0, 10)} -{' '}
                {eventData.end_date.substring(0, 10)}
              </span>
            )}
            </div>
          </div>
          &#xa0;
        </div>
        <br clear="all"/>
      </div>
    </div>

      <div className="mb-4 text-white">

        {/* Display available trophies based on the division data */}
        <div className="text-l font-bold">Available Trophies</div>
        <div className="trophies-info">
          <span>Gold: {division?.tiers1}</span>
          &#xa0;|&#xa0;
          <span>Silver: {division?.tiers2}</span>
          &#xa0;|&#xa0;
          <span>Bronze: {division?.tiers3}</span>
          &#xa0;|&#xa0;
          <span>Participation: {division?.tiers4}</span>
          &#xa0;|&#xa0;
          <span>MVP: {division?.tiers5}</span>
        </div>
      </div>

      <div className="vvblock">
          {/* Team Selection Dropdown */}
          <div className="vvblocktitle">Select a Team</div>
          <select
            className="inputFieldGeneral"
            value={team?.id || ''}
            onChange={e => setTeam(teams.find(team => team.id.toString() === e.target.value))}
            disabled={!teams.length}
          >
            <option value="">Select a team</option>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </div>

      {team && (
      <div className="vvblock">
      <h2 className="text-2xl font-bold">{team?.club_name} : {team?.name}</h2>
      <br/>
      <h2 className="font-bold">Award Whole Team</h2>
      <br/>

        {/* List users and allow tier selection */}
        <div className="team">

            <div className="team-item">
              <span>{team?.name}</span>
              <div class="custom-select-wrapper">

              <select
                  className="selectFieldDistributeTrophy"
                  value={teamTierSelection}
                  onChange={e => handleTeamTierChange(e.target.value)}
                  disabled={!division}
                >
                <option value="" selected>Select tier</option>
                <option key="tiers1" value="tiers1" disabled={availableTrophies.tiers1 <= 0}>Gold</option>
                <option key="tiers2" value="tiers2" disabled={availableTrophies.tiers2 <= 0}>Silver</option>
                <option key="tiers3" value="tiers3" disabled={availableTrophies.tiers3 <= 0}>Bronze</option>
                <option key="tiers4" value="tiers4" disabled={availableTrophies.tiers4 <= 0}>Participation</option>
                <option key="tiers5" value="tiers5" disabled={availableTrophies.tiers5 <= 0}>MVP</option>

              </select>
              </div>
              <br clear="all"/>
            </div>
        </div>
      </div>
      )}

      <div className="vvblock">
        <h2 className="font-bold">Award Individuals</h2>
        <br/>
        {/* List users and allow tier selection */}
        <div className="users-list">
          {users.map(user => (
            <div key={user.id} className="user-item">
              <span>{user.first_name} {user.last_name}</span>
              <div class="custom-select-wrapper">

              <select
                className="selectFieldDistributeTrophy"
                value={selections[user.id] || ""}
                onChange={e => handleTierChange(user.id, e.target.value)}
                disabled={!division} // Disable if division data is not loaded
              >
                <option value="">Select tier</option>
                <option key="tiers1" value="tiers1" disabled={availableTrophies.tiers1 <= 0}>Gold</option>
                <option key="tiers2" value="tiers2" disabled={availableTrophies.tiers2 <= 0}>Silver</option>
                <option key="tiers3" value="tiers3" disabled={availableTrophies.tiers3 <= 0}>Bronze</option>
                <option key="tiers4" value="tiers4" disabled={availableTrophies.tiers4 <= 0}>Participation</option>
                <option key="tiers5" value="tiers5" disabled={availableTrophies.tiers5 <= 0}>MVP</option>

              </select>
              </div>
              <br clear="all"/>
            </div>
          ))}
        </div>
        <br/>
      </div>

      <button onClick={handleDistribute} className="distribute-btn distribute-button">Distribute</button>
      <br/><br/>
    </div>

    {showSuccessDialog && (
      <div className="success-dialog">
        <div className="dialog-content">
          <h2 className="generalmodaltitle ">Success!</h2>
          <br/>
          <p>The trophies have been successfully distributed to the participants.</p>
          <br/><br/>
          <button className="big-btn" onClick={closeDialog}>Close</button>
        </div>
      </div>
    )}


    </>
  );
}

export default AdminDistributeTrophies;
