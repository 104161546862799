import { useState, useEffect, useContext } from 'react';
import { UserContext } from './App';

function AdminNewTeam() {

  const { user, setUser } = useContext(UserContext);
  const [teams, setTeams] = useState([]);
  const [clubs, setClubs] = useState([]); // New state for clubs
  const [newTeamName, setNewTeamName] = useState(''); // State for new team name
  const [selectedClubId, setSelectedClubId] = useState('default'); // State for selected club ID


  useEffect(() => {
    // Fetching user data


      // Fetching teams data
      fetch(`/api/listTeams/${user.userId}`)
        .then(response => response.json())
        .then(data => {
          const teamData = data.map(team => ({
            id: team.id,
            name: team.name,
            club_name: team.club_name,
          }));
          setTeams(teamData);
        });

      // Fetching clubs data
    /*fetch(`/api/listClubs/${user.userId}`)*/
    fetch('/api/listAllClubs')
      .then(response => response.json())
      .then(data => setClubs(data));


  }, []);

  function listTeams() {
    // Fetching teams data
    fetch(`/api/listTeams/${user.userId}`)
      .then(response => response.json())
      .then(data => {
        const teamData = data.map(team => ({
          id: team.id,
          name: team.name,
          club_name: team.club_name,
        }));
        setTeams(teamData);
      });
  }

  const handleSaveTeam = () => {
    // Check if the team name is not filled in
    if (!newTeamName) {
      alert('Please fill in the team name before saving.');
      return; // Stop the function execution here
    }

    // Check if the club is not selected
    if (selectedClubId === 'default') {
      alert('Please select a club before saving.');
      return; // Stop the function execution here
    }

    // Send a POST request to your API to save the new team
    fetch('/api/addTeam', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: user.userId,
        teamName: newTeamName,
        clubId: selectedClubId,
      }),
    })
      .then(response => {
        if (!response.ok) {
          // Handle HTTP errors
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        // Assuming the API response includes the new team data, you can update the state
        const newTeam = {
          id: data.id,
          name: newTeamName,
          clubId: selectedClubId,
        };
        //setTeams([newTeam, ...teams]);
        listTeams();
        setNewTeamName('');
        setSelectedClubId('default');
      })
      .catch(error => {
        // More detailed error handling based on the error type or message
        console.error('Error saving the new team:', error.message);
        alert(`Failed to save the new team. Error: ${error.message}`);
      });
  };

  return (
    <div className="h-screen main">


      <div className="vvblock" id="newteamcontrols">
        <div className="vvblocktitle">Create a New Team</div>
        <br/>
        <input
          type="text"
          placeholder="Team Name"
          className="inputFieldGeneral"
          value={newTeamName}
          onChange={e => setNewTeamName(e.target.value)}
        />
        <select
          className="inputFieldGeneral"
          value={selectedClubId}
          onChange={e => setSelectedClubId(e.target.value)}
        >
          <option value="default" disabled>Select Club</option>
          {clubs.map(club => (
            <option key={club.id} value={club.id}>
              {club.name}
            </option>
          ))}
        </select>
        <br/><br/>
        <button className="big-btn" onClick={handleSaveTeam}>
          Save
        </button>
      </div>

      <div className="vvblock" id="adminteams">
        <div id="teamtitle" className="vvblocktitle">My Teams</div>

        {teams.length > 0 ? (
          teams.map(team => (
            <div className="team" key={team.id}>
              <div className="team-name"><b>{team.club_name}</b> : {team.name}</div>
            </div>
          ))
        ) : (
          <div className="text-center">You have no Teams yet</div>
        )}
      </div>
    </div>
  );
}

export default AdminNewTeam;
