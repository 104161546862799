import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './App';


function Header() {

  const { user, setUser } = useContext(UserContext);

  const navigate = useNavigate();


  useEffect(() => {
      const fetchUser = async () => {
        try {
          const response = await fetch('/api/current_user', {
            credentials: 'include'
          });
          const data = await response.json();
          if (data.user && data.user.role == 'admin') {
            // Fetch the profile picture URL from a separate API endpoint
            let profilePicData = null;

            const profilePicResponse = await fetch(`/api/getUserProfile/${data.user.id}`, {
                credentials: 'include'
            });
            profilePicData = await profilePicResponse.json();

            console.info("PROFILE", profilePicData);


            let profilePicUrl = '';

            if(Array.isArray(profilePicData) && profilePicData.length > 0) {
              profilePicUrl = profilePicData[0].profile_picture_url;
            }

            setUser({
              firstName: data.user.first_name,
              lastName: data.user.last_name,
              email: data.user.email,
              userId: data.user.id,
              profilePicUrl: profilePicUrl,
              gender: data.user.gender
            });



          } else {
            console.log('User not authenticated, redirecting...');
            navigate('/SignIn');


          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };


      fetchUser();
    }, [navigate]);

    function handleBack() {
        navigate(-1); // Goes back to the previous page
    }

    function handleHome() {
        navigate('/AdminHome');
    }


    return (
      <>
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="crossorigin"></link>
        <link href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700;900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Bungee&display=swap" rel="stylesheet"></link>
        <a className="logout" href="/login/logout">
          <img src="/images/logout.png"/>
        </a>

        <Link to={`/AdminUserProfile/${user.userId}`} className="settings">
          <img src="/images/settings.png"/>
        </Link>
        <div className="back_home">
          <img onClick={handleBack} className="backimg" src="/images/back.png"/>
          <img onClick={handleHome} className="homeimg" src="/images/home.png"/>
        </div>
      </>
    );


}


export default Header;
