import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

function AdminEvent() {
  const { eventId } = useParams(); // Get the eventId from the route parameters

  const [eventData, setEventData] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [currentDivision, setCurrentDivision] = useState({});

  const [qrCodeFileName, setQrCodeFileName] = useState(''); // New state for qr code filename
  const [isDialogOpen, setIsDialogOpen] = useState(false); // New state to handle dialog visibility

  function handleNotImplemented() {
    alert("This feature is disabled for this demonstration");
  }

  useEffect(() => {
    // Fetch event data
    fetch(`/api/getEvent/${eventId}`)
      .then((response) => response.json())
      .then((data) => {
        setEventData(data.event);
        setDivisions(data.divisions);
      });

  }, [eventId]);

  const closeDialog = () => {
    setIsDialogOpen(false);
  }

  const toggleDialog = (eventId, division) => {
    // Fetching user data
    fetch('/api/createQRCodeForEventAndDivision/' + eventId + '/' + division.id)
      .then(response => response.json())
      .then(data => {
        console.info(data);

        // Extract the qrcodeFileName from the data
        const qrcodeFileName = data.qrcodeFileName;

        setCurrentDivision(division);

        // Open the dialog and pass the qrcodeFileName to it
        setIsDialogOpen(!isDialogOpen);
        setQrCodeFileName(qrcodeFileName); // Assuming you have a state variable to store the filename
      });
  };

  function copyLinkToClipboard() {
    const copyText = document.getElementById("copyableLink");
    const textArea = document.createElement("textarea");
    textArea.value = copyText.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    alert("Link copied to clipboard!");
  }




  return (
    <div className="h-screen">

      <div className="homeblock headertop relative">
        <Link to="/" className="homelink absolute text-black font-bold text-left hidden">/</Link>
        <div className="logout hidden"><a href="/login/logout">logout</a></div>
        <div className="" id="user">
          <div id="userpic">
            {eventData.profile_pic_url ? (
              <img src={eventData.profile_pic_url} alt="Event Profile" />
            ) : (
              <img src="/images/eventbg.png" alt="Event Profile" />
            )}
          </div>
          <div id="userinfowrapper">
            <div id="userinfo">
              <div id="usernameui">
              {eventData?.name || 'Loading event...'}
              </div>
              <div>
              {eventData.start_date && eventData.end_date && (
                <span>
                  {eventData.start_date.substring(0, 10)} -{' '}
                  {eventData.end_date.substring(0, 10)}
                </span>
              )}
              </div>
            </div>
            &#xa0;
          </div>
          <br clear="all"/>
        </div>
      </div>




      <div className="vvblock" id="eventDivisions">
        <div className="vvblocktitle" >
          Available Trophies
        </div>

        {divisions.length > 0 ? (
          divisions.map((division) => (
            <>
            <div className="division" key={division.id}>
              <div className="divisionName">{division.name}</div>

              <br/>
              <div className="flex justify-between items-end">
                <div className="divisionTiers">
                  Gold: {division.tiers1}<br/>
                  Silver: {division.tiers2}<br/>
                  Bronze: {division.tiers3}<br/>
                  Participation: {division.tiers4}<br/>
                  MVP: {division.tiers5}
                </div>
                <div className="divisionButtons">
                  {/*
                  <button className="small-btn" onClick={() => toggleDialog(eventId, division)}>
                    Invite
                  </button>
                  */}
                  <button className="small-btn text-gray" onClick={handleNotImplemented}>
                    Invite
                  </button>
                  <Link
                    to={`/AdminDistributeTrophies/${eventId}/${division.id}`}
                    className="small-btn inline-block mt-4"
                  >
                    Distribute
                  </Link>
                </div>
              </div>
            </div>
            <br/><br/>
            </>
          ))
        ) : (
          <div className="text-center">No divisions available for this event.</div>
        )}
      </div>

      {/* Dialog */}
      {isDialogOpen && (
        <div className="dialog-overlay" onClick={toggleDialog}>
          <div className="dialog" onClick={(e) => e.stopPropagation()}> {/* Prevent the overlay click event */}
            {/* Close Button */}
            <button className="close-button" onClick={closeDialog}>&times;</button>

            {/* The content of the dialog */}
            <div className="generalmodaltitle">INVITE TO EVENT</div>
            <br/>
            <div className="font-bold">
              {eventData.name ? (
                <span>{eventData.name} : {currentDivision.name}</span>
              ) : (
                <span>Loading...</span>
              )}
            </div>
            <br/>

            <div>
              You can invite users to the event and division with this <Link id="copyableLink" to={`https://app.yeess.io/UserJoinEventAndDivision/${eventId}/${currentDivision.id}`}><u>link shorturl</u></Link> <span onClick={copyLinkToClipboard} style={{ cursor: "pointer" }}>(copy link)</span>

              <br/><br/>
              Or with this QR Code (<a
                href={`/images/${qrCodeFileName}`}
                download={`QR_Code_${eventData.name.replace(/\s/g, '_')}_${currentDivision.name.replace(/\s/g, '_')}.png`} // Replace spaces with underscores
              >download QR code</a>)
              <br/><br/>
              <div className="text-center">
                {/* Use the qrcodeFileName as the source for the QR code */}
                <img className="qr" src={`/images/${qrCodeFileName}`} />
              </div>
            </div>
            {/*... other content from your image */}
          </div>
        </div>
      )}

    </div>
  );
}

export default AdminEvent;
