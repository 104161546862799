import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from './App';


function UserHeaderMini() {

  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('/api/current_user', {
          credentials: 'include' // Important for cookies to be sent and received
        });
        const data = await response.json();
        if (data.user) {

          // Update state with user data
          setUser({
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
            userId: data.user.id // Assuming the API provides a user ID field
          });
        } else if (!location.pathname.startsWith('/UserJoinTeam')) {
          console.log('User not authenticated, redirecting...');
          const currentPath = encodeURIComponent(location.pathname);
          navigate(`/SignIn?redirectTo=${currentPath}`);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [navigate]);

  return (



    <nav className="bg-black p-4 mb-2">
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="crossorigin"></link>
      <link href="https://fonts.googleapis.com/css2?family=Urbanist:wght@400;500;700&display=swap" rel="stylesheet"></link>
      <div className="container mx-auto">
          <Link to="/" className="block float-left text-white font-bold text-left">/</Link>
          <div className="text-white underline font-bold float-right ml-4"><a href="/login/logout">logout</a></div>
          <div className="text-white font-bold float-right text-right">
          {user.userId && (
             <Link to={`/UserProfile/${user.userId}`} className="text-white font-bold float-right text-right">
               {user.firstName} {user.lastName}
             </Link>
           )}
          </div>
          <br clear="all"/>
      </div>
    </nav>


  );
}

export default UserHeaderMini;
