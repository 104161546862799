import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';
import { UserContext } from './App';
import axios from 'axios';



function UserJoinEventAndDivision() {
  const { eventId, divisionId } = useParams(); // Assuming eventId and divisionId are part of the route params
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sharePermission, setSharePermission] = useState(false);
  const [eventData, setEventData] = useState({});
  const [division, setDivision] = useState({});
  const [showModal, setShowModal] = useState(false); // state to control modal visibility
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // New state for confirmation modal

  const [imageFile, setImageFile] = useState(null); // state to store the actual file
  const [selectedImage, setSelectedImage] = useState(null);

  const { user, setUser } = useContext(UserContext);




  useEffect(() => {
    // Fetching event data
    fetch(`/api/getEvent/${eventId}`)
      .then(response => response.json())
      .then(data => {
        console.info("DATA", data);
        // assuming the event data is in a similar format
        setEventData(data.event);

      });

    // Fetching division data
    fetch(`/api/getDivision/${divisionId}`)
      .then(response => response.json())
      .then(data => {
        // assuming the division data is in a similar format
        const divisionData = {
          id: data.id,
          name: data.name,
          // additional division details
        };
        setDivision(divisionData);
      });

      //fetchUser();

  }, [eventId, divisionId]);




  const handleJoinClick =  async (e)  => {
    e.preventDefault();

    // Show the confirmation modal instead of submitting directly
    setShowConfirmationModal(true);
  };

  const handleConfirmJoin = async () => {
    setShowConfirmationModal(false);

    const formData = new FormData();

    const payload = {
      userId: user.userId,
      eventId: eventId,
      divisionId: divisionId
    };

    axios.post(`/api/addUserToEventAndDivision`, payload)
      .then(response =>
      {
        if (response.data.success) {
          setShowModal(true);
        } else {
          console.error('Failed to join the event and division:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Failed to join the event and division');
        // Handle error (e.g., show an error message)
      });
    };





  return (
    <div>


    <div className="vvblock">
          <div class="vvblocktitle">Invitation</div>
          <div className="generaltitle vvinnerblock vvinnerblock-bigger"><b>Hi {user.firstName}!</b></div>
          <div className="vvinnerblock vvinnerblock-bigger">You have been invited to participate in the {eventData.name ? (
            <><b>{eventData.name}</b> event, where you will be competing in the <b>{division.name}</b> division.</>
          ) : (
            <span>...</span>
          )}</div>
    </div>
          <form onSubmit={handleJoinClick}>




            <button type="submit" className="jointeam-btn font-bold text-center bg-black" type="submit">Continue</button>

          </form>
          <br/><br/>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="modal">
          <div className="modal-content text-center">
            <span className="close" onClick={() => setShowConfirmationModal(false)}>&times;</span>
            <h3 className="generalmodaltitle">INVITATION</h3>
            <br/>
            <div className="">{eventData?.profile_pic_url ? (
              <img src={eventData.profile_pic_url} alt="Event Profile" />
            ) : (
              <img src="/images/eventbg.png" alt="Event Profile" /> // Placeholder if no picture is available
            )}</div>
            <br/>
            <p>Are you taking part in <b>{eventData.name} : {division.name}</b>?</p>
            <br/>
            <button className="big-btn" onClick={handleConfirmJoin}>Join Event</button>
            <br/><br/>
            <div className="underline cursor-pointer" onClick={() => setShowConfirmationModal(false)}>No, this is not my event, I don't want to join</div>
          </div>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <h2 className="generalmodaltitle">Success!</h2>
            <br/>
            <p>You successfully joined the event <b>{eventData.name}</b>!</p>
            <br/><br/>
            <button className="big-btn" onClick={() => setShowModal(false)}>Ok</button>
          </div>
        </div>
      )}

    </div>

  );
}

export default UserJoinEventAndDivision;
