//import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route, Outlet, useNavigate, useLocation } from 'react-router-dom';


import Home from './Home';
import Header from './Header';
import HeaderNoHeader from './HeaderNoHeader';
import HeaderSignIn from './HeaderSignIn';

import HeaderWelcome from './HeaderWelcome';

import UserHeader from './UserHeader';
import UserHeaderMini from './UserHeaderMini';

import Modal from './Modal';

import Terms from './Terms';

import Footer from './Footer';
import UserTrophy from './UserTrophy';
import TestUI from './TestUI';
import UserJoinTeam from './UserJoinTeam';
import UserJoinEventAndDivision from './UserJoinEventAndDivision';
import UserTrophies from './UserTrophies';
import UserProfile from './UserProfile';

import Trophy from './Trophy';

import SignIn from './SignIn';
import SignUp from './SignUp';
import AdminSignUp from './AdminSignUp';


import AdminHeader from './AdminHeader';
import AdminUserProfile from './AdminUserProfile';

import AdminHome from './AdminHome';
import AdminNewTeam from './AdminNewTeam';
import AdminNewEvent from './AdminNewEvent';
import AdminEnterEvent from './AdminEnterEvent';
import AdminEvent from './AdminEvent';
import AdminTeam from './AdminTeam';
import AdminDistributeTrophies from './AdminDistributeTrophies';
import AdminSelectTrophyStyle from './AdminSelectTrophyStyle';

import ToggleSwitch from './ToggleSwitch';

export const UserContext = React.createContext({
  user: {
    firstName: '',
    lastName: '',
    email: '',
    userId: null,
    gender: ''
  },
  setUser: () => {} // empty setter function
});


//import About from './About';
//import Contact from './Contact';

function LayoutWithHeader() {



  /**
   * CHECK THE LOCATION TO GET THE RIGHT TEMPLATES
   */

  const location = useLocation();

  // Determine which header to render based on the route


  let HeaderToRender;
  let MainClass;
  if(location.pathname === '/' || location.pathname.startsWith('/UserProfile') || location.pathname.startsWith('/UserJoinTeam')) {
    HeaderToRender = UserHeader;
    MainClass = "main-user";
  } else if (location.pathname.startsWith('/UserTrophy')) {
    HeaderToRender = HeaderNoHeader;
    MainClass = "main-user";
  } else if (location.pathname.startsWith('/UserJoinTeam')) {
    HeaderToRender = HeaderNoHeader;
    MainClass = "main-user";
  } else if (location.pathname.startsWith('/UserJoin')) {
    HeaderToRender = UserHeader; //HeaderNoHeader;
    MainClass = "main-user";
  } else if (location.pathname.startsWith('/User')) {
    HeaderToRender = UserHeaderMini;
    MainClass = "main-user";
  }  else if (location.pathname === '/AdminSignUp' || location.pathname === '/SignIn' || location.pathname === '/SignUp' || location.pathname === '/Terms') {
    MainClass = "main-user";
    HeaderToRender = HeaderSignIn;
  } else if (location.pathname.startsWith('/Admin')) {
    MainClass = "main";
    //HeaderToRender = AdminHeader;
    HeaderToRender = Header;
  } else {
    HeaderToRender = Header; // The default header, if you have one
  }

  return (
    <>
      <div className={`h-screen ${MainClass}`}>
        <HeaderToRender />
        <Outlet />
      </div>
    </>
  );
}


function App() {

  /**
   * GET USER INFO AND SAVE IT TO CONTEXT
   */

   const [user, setUser] = useState({
     firstName: '',
     lastName: '',
     email: '',
     userId: null,
     profilePicUrl: '',


   });

   useEffect(() => {
     const fetchUser = async () => {
       try {
         const response = await fetch('/api/current_user', {
           credentials: 'include'
         });
         const data = await response.json();
         if (data.user) {
           console.info("USR ", data.user);
           // Fetch the profile picture URL from a separate API endpoint
           let profilePicData = null;

           const profilePicResponse = await fetch(`/api/getUserProfile/${data.user.id}`, {
               credentials: 'include'
           });
           profilePicData = await profilePicResponse.json();

           console.info("APP PROFILE", profilePicData);


           let profilePicUrl = '';

           if(Array.isArray(profilePicData) && profilePicData.length > 0) {
             profilePicUrl = profilePicData[0].profile_picture_url;
           }

           setUser({
             firstName: data.user.first_name,
             lastName: data.user.last_name,
             email: data.user.email,
             userId: data.user.id,
             profilePicUrl: profilePicUrl,
             gender: data.user.gender
           });



         } else {
           console.log('User not authenticated, redirecting...');
           //navigate('/SignIn');


         }
       } catch (error) {
         console.error('Error fetching user data:', error);
       }
     };


     fetchUser();

   }, []);


   const userContextValue = { user, setUser };

   //const navigate = useNavigate();



   console.info("APP USER",user);


  return (


      <UserContext.Provider value={userContextValue}>


      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
        <BrowserRouter>

          <Routes>
            <Route element={<LayoutWithHeader />}>

              <Route path="/" element={<Home />} />
              <Route path="/SignIn" element={<SignIn />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/AdminSignUp" element={<AdminSignUp />} />´
              <Route path="/Terms" element={<Terms />} />

              <Route path="/Trophy" element={<Trophy />} />
              <Route path="/TestUI" element={<TestUI />} />
              <Route path="/UserJoinTeam/:teamId" element={<UserJoinTeam />} />
              <Route path="/UserJoinEventAndDivision/:eventId/:divisionId" element={<UserJoinEventAndDivision />} />

              <Route path="/UserTrophies/" element={<UserTrophies />} />
              <Route path="/UserTrophy/:trophyId" element={<UserTrophy />} />
              <Route path="/UserProfile/:userId" element={<UserProfile />} />


              <Route path="/AdminHome" element={<AdminHome />} />
              <Route path="/AdminNewTeam" element={<AdminNewTeam />} />
              <Route path="/AdminNewEvent" element={<AdminNewEvent />} />

              <Route path="/AdminSelectTrophyStyle" element={<AdminSelectTrophyStyle />} />
              <Route path="/AdminEnterEvent" element={<AdminEnterEvent />} />
              <Route path="/AdminUserProfile/:userId" element={<AdminUserProfile />} />


              <Route path="/AdminEvent/:eventId" element={<AdminEvent />} />
              <Route path="/AdminTeam/:teamId" element={<AdminTeam />} />
              <Route path="/AdminDistributeTrophies/:eventId/:divisionId" element={<AdminDistributeTrophies />} />



              </Route>

              {/* Using path="*"" means "match anything", so this route
                    acts like a catch-alCl for URLs that we don't have explicit
                    routes for. */}
          </Routes>
        </BrowserRouter>

      </UserContext.Provider>


  );
}

export default App;
