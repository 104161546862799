import { useState, useEffect, useContext } from 'react';
import { useParams, Link  } from 'react-router-dom';
import { UserContext } from './App';


function AdminTeam() {

  const { teamId } = useParams(); // Get the releaseid from the route parameters

  const { user, setUser } = useContext(UserContext);
  const [team, setTeam] = useState({});
  const [users, setUsers] = useState([]); // New state for users
  const [qrCodeFileName, setQrCodeFileName] = useState(''); // New state for qr code filename

  const [isDialogOpen, setIsDialogOpen] = useState(false); // New state to handle dialog visibility

  function handleNotImplemented(event) {
    event.preventDefault();
    alert("This feature is disabled at the moment");
  }

  const toggleDialog = () => {
    // Fetching user data
    fetch('/api/createQRCode/' + teamId)
      .then(response => response.json())
      .then(data => {
        console.info(data);

        // Extract the qrcodeFileName from the data
        const qrcodeFileName = data.qrcodeFileName;

        // Open the dialog and pass the qrcodeFileName to it
        setIsDialogOpen(!isDialogOpen);
        setQrCodeFileName(qrcodeFileName); // Assuming you have a state variable to store the filename
      });
  };

  useEffect(() => {




    // Fetching user data
    fetch('/api/listUsersInTeam/' + teamId)
      .then(response => response.json())
      .then(data => {
        const userData = data.map(teamuser => ({
          id: teamuser.id,
          first_name: teamuser.first_name,
          last_name: teamuser.last_name,
          email: teamuser.email,
        }));
        setUsers(data);
      });


      // Fetching teams data
      fetch('/api/getTeam/' + teamId)
        .then(response => response.json())
        .then(data => {
          const teamData = {
            id: data[0].id,
            name: data[0].name,
            club_name: data[0].club_name,
            hash: data[0].idhash
          };
          setTeam(teamData);
        });



  }, []);

  function copyLinkToClipboard() {
    const copyText = document.getElementById("copyableLink");
    const textArea = document.createElement("textarea");
    textArea.value = copyText.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    alert("Link copied to clipboard!");
  }



  return (
    <div className="homeblock headertop relative">
    <Link to="/" className="homelink absolute text-black font-bold text-left hidden">/</Link>

    <div className="" id="user">
      <div id="userpic">
        {user.profilePicUrl ? (
          <img src={user.profilePicUrl} alt="User Profile" />
        ) : (
          <div>&#xa0;</div> // Placeholder if no picture is available
        )}
      </div>
      <div id="userinfowrapper">
        <div id="userinfo">
          <div id="usernameui">


          {user.userId && (
             <Link to={`/AdminUserProfile/${user.userId}`}>
               {user.firstName} {user.lastName}
             </Link>
           )}


          </div>
          <div id="userlocation">{user.club}</div>
        </div>
        &#xa0;
      </div>
      <br clear="all"/>
    </div>


      <div className="homeblock" id="newteamcontrols">

        <div className="vvblock" id="adminevents">
          <div className="vvblocktitle">Team Members</div>



          {/* Dialog */}
          {isDialogOpen && (
            <div className="dialog-overlay" onClick={toggleDialog}>
              <div className="dialog" onClick={(e) => e.stopPropagation()}> {/* Prevent the overlay click event */}
                {/* Close Button */}
                <button className="close-button" onClick={toggleDialog}>&times;</button>

                {/* The content of the dialog */}
                <div className="generalmodaltitle">INVITE TO TEAM</div>
                <br/>
                <div className="font-bold">
                  {team.name ? (
                    <span>{team.club_name} : {team.name}</span>
                  ) : (
                    <span>Loading...</span>
                  )}
                </div>
                <br/>


                <div>
                  You can invite team members with this <Link id="copyableLink" to={`https://app.yeess.io/UserJoinTeam/${team.hash}`}><u>link shorturl</u></Link> <span onClick={copyLinkToClipboard} style={{ cursor: "pointer" }}>(copy link)</span>
                  <br/><br/>
                  Or with this QR Code<br/>
                  (<a
                    href={`/images/${qrCodeFileName}`}
                    download={`QR_Code_${team.club_name.replace(/\s/g, '_')}_${team.name.replace(/\s/g, '_')}.png`} // Replace spaces with underscores
                  >download QR code</a>)
                  <br/><br/>
                  <div className="text-center">
                    {/* Use the qrcodeFileName as the source for the QR code */}
                    <img className="qr" src={`/images/${qrCodeFileName}`} />
                  </div>
                </div>
                {/*... other content from your image */}
              </div>
            </div>
          )}

          <div className="font-bold">{team.name ? (
            <span>{team.club_name} : {team.name}</span>
          ) : (
            <span>Loading...</span>
          )}</div>

          {users.length > 0 ? (
            users.map(teamuser => (
              <Link onClick={(e) => handleNotImplemented(e)} to={`/User/${teamuser.id}`} className="teamuser" key={teamuser.id}>
                {teamuser.first_name} {teamuser.last_name}
              </Link>
            ))
          ) : (
            <div className="text-center mt-4">Team has no members</div>
          )}
          <br/>
          <button className="big-btn" onClick={toggleDialog}>
          Invite new team members
          </button>
        </div>

        <br/><br/>

      </div>


    </div>
  );
}

export default AdminTeam;
