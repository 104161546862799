import React, { useState, useEffect, useContext } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';
import { UserContext } from './App';

function AdminUserProfile() {
  const { userId } = useParams();
  const [userTeams, setUserTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sharePermission, setSharePermission] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);

  const { user, setUser } = useContext(UserContext);

  // New state for showing the confirmation modal and storing the selected teamId
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [selectedTeamName, setSelectedTeamName] = useState('');


  const handleLeaveTeam = (teamId, teamName) => {
    setSelectedTeamId(teamId); // Store the teamId for future reference
    setSelectedTeamName(teamName); // Store the team name
    setShowConfirmationModal(true); // Show the confirmation modal
  };


  // New function to handle the actual leaving process
  const confirmLeaveTeam = async () => {
    if (selectedTeamId) {
      try {
        const response = await fetch('/api/UserLeaveTeam', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, teamId: selectedTeamId }),
        });

        if (!response.ok) {
          throw new Error('Failed to leave the team');
        }

        const data = await response.json();
        console.log('Leave team response:', data);

        setUserTeams(prevTeams => prevTeams.filter(team => team.id !== selectedTeamId));
        setShowConfirmationModal(false); // Close the modal upon successful operation
      } catch (error) {
        console.error('Error leaving the team:', error);
      }
    }
  };





  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(URL.createObjectURL(img));

      // Prepare form data
      const formData = new FormData();
      formData.append('image', img); // 'image' is the key your backend will look for
      formData.append('userId', userId); // Add userId to the form data

      try {
        // API call to the server
        const response = await fetch('/api/uploadProfilePicture', {
          method: 'POST',
          body: formData,
          // Note: When sending FormData, don't set Content-Type header,
          // the browser will set it to 'multipart/form-data' automatically
        });

        if (!response.ok) {
          throw new Error('Server responded with an error!');
        }

        // Optionally, handle the response data
        const responseData = await response.json();
        console.log('Server response:', responseData);

        // Update user context with the new profile picture URL
        if (responseData.profilePicUrl) {
          console.info("USER", user);

          setUser({
            ...user,
            profilePicUrl: responseData.profilePicUrl
          });
        }
      } catch (error) {
        console.error('Error uploading the image:', error);
      }
    }
  };



  useEffect(() => {
    const fetchTeamsForUser = async () => {
      try {
        const response = await fetch(`/api/getTeamsForUser/${userId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserTeams(data);

        const sharePermissionResponse = await fetch(`/api/getUserSharePermission/${userId}`);
        if (!sharePermissionResponse.ok) {
          throw new Error('Network response was not ok');
        }
        const sharePermissionData = await sharePermissionResponse.json();
        setSharePermission(sharePermissionData.share_permission);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamsForUser();
  }, [userId]);

  const handleSharePermissionChange = async (checked) => {
    try {
      const response = await fetch('/api/setUserSharePermission', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, sharePermission: checked }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSharePermission(checked); // Update state only if request is successful
    } catch (error) {
      console.error('Error setting share permission:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>

    <div className="homeblock headertop relative">

      <div className="" id="user">
        <div id="userpic">
          {user.profilePicUrl ? (
            <img src={user.profilePicUrl} alt="User Profile" />
          ) : (
            <div>&#xa0;</div> // Placeholder if no picture is available
          )}
        </div>
        <div id="userinfowrapper">
          <div id="userinfo">
            <div id="usernameui">


            {user.userId && (
               <Link to={`/AdminUserProfile/`}>
                 {user.firstName} {user.lastName}
               </Link>
             )}


            </div>
            <div id="userlocation">{user.club}</div>
          </div>
          &#xa0;
        </div>
        <br clear="all"/>
      </div>
    </div>

      <div className="vvblock">
        <div className="vvblocktitle">
          Settings
        </div>
        <div className="vvinnerblock">
          <div className="float-left profilesharetext">
            Can we share your achievements on our social media and tag you?
          </div>
          <div className="float-right profilesharetoggle">
          <ToggleSwitch
            id="share-permission"
            name="toggleSwitch"
            checked={sharePermission}
            onChange={(newState) => handleSharePermissionChange(newState)}
          />
          </div>
          <br clear="all"/>
        </div>
        <div className="vvinnerblock">
          Connect your social accounts:
        </div>
        <div className="vvinnerblock">
          <div className="profileshareicons">
            <img src="/images/profile_logo_fb.png"/>

            <img src="/images/profile_logo_x.png"/>

            <img src="/images/profile_logo_ig.png"/>

            <img src="/images/profile_logo_tiktok.png"/>
          </div>
          <br clear="all"/>
        </div>

        <div className="vvinnerblock profileaddprofilepic">
          <input type="file" onChange={handleImageChange} style={{ display: 'none' }} id="fileInput" />
          <label htmlFor="fileInput" className="profileaddprofilepic_pic">
            {user.profilePicUrl ? (
              <img src={user.profilePicUrl} alt="User Profile" />
            ) : (
              <div>&#xa0;</div> // Placeholder if no picture is available
            )}
          </label>
          <div className="profileaddprofilepic_text" onClick={() => document.getElementById('fileInput').click()}>
          {user.profilePicUrl ? (
            <>Update profile picture</>
          ) : (
            <>Add a profile picture</>
          )}
          </div>
        </div>



      </div>


      
      <br/>

      {/* Confirmation Modal for Leaving Team */}
        {showConfirmationModal && (
          <div className="modal">
            <div className="modal-content text-center">
              <span className="close" onClick={() => setShowConfirmationModal(false)}>&times;</span>
              <h3 className="generalmodaltitle">Confirm Action</h3>
              <br/>
              {/* Updated message to include team name */}
              <p>Are you sure you want to leave your team <b>{selectedTeamName}</b>?</p>
              <br/>
              <button className="big-btn" onClick={confirmLeaveTeam}>Yes, Leave</button>
              <br/><br/>
              <div onClick={() => setShowConfirmationModal(false)} className="underline cursor-pointer">No, Stay</div>
            </div>
          </div>
        )}

    </>
  );
}

export default AdminUserProfile;
