import { useState, useEffect, useContext } from 'react';
import { Link,useParams, useNavigate } from 'react-router-dom';
import ToggleSwitch from './ToggleSwitch';

import { UserContext } from './App';

function UserJoinTeam() {

  const { user, setUser } = useContext(UserContext);
  const { teamId } = useParams(); // Assuming teamId is part of the route params
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [sharePermission, setSharePermission] = useState();
  const [team, setTeam] = useState({});
  const [showModal, setShowModal] = useState(false); // state to control modal visibility
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // New state for confirmation modal

  const [imageFile, setImageFile] = useState(null); // state to store the actual file


  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {




      // Fetching teams data
      fetch('/api/getTeam/' + teamId)
        .then(response => response.json())
        .then(data => {
          if (data.length === 0 || !data[0].id) { // Check if data is empty or does not contain valid team information
            // If no data found, set team state to null to indicate "Nothing found."
            setTeam(null);
          } else {
            const teamData = {
              id: data[0].id,
              name: data[0].name,
              club_name: data[0].club_name,
            };
            setTeam(teamData);
          }
          setLoading(false); // Data has been processed, loading is complete
        })
        .catch(error => {
          console.error('Failed to fetch team:', error);
          setLoading(false); // Ensure loading state is false even if an error occurs
        });

      if(user.userId) {
        fetch('/api/getUserSharePermission/' + user.userId)
          .then(response => response.json())
          .then(data => {
            const sharePermissionData = {
              sharePermission: data.share_permission,
            };
            console.info(sharePermissionData.sharePermission);
            setSharePermission(sharePermissionData.sharePermission);
          });
      }





  }, [teamId, user.userId]);

  useEffect(() => {
    if(user.userId) {
      fetch('/api/getUserSharePermission/' + user.userId)
        .then(response => response.json())
        .then(data => {
          const sharePermissionData = {
            sharePermission: data.share_permission,
          };
          console.info(sharePermissionData.sharePermission);
          setSharePermission(sharePermissionData.sharePermission);
        });
    }
  }, [sharePermission]);


  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(URL.createObjectURL(img));
      setImageFile(img); // store the file

      // Prepare form data
      const formData = new FormData();
      formData.append('image', img); // 'image' is the key your backend will look for

      formData.append('userId', user.userId); // Add userId to the form data

      try {
        // API call to the server
        const response = await fetch('/api/uploadProfilePicture', {
          method: 'POST',
          body: formData,
          // Note: When sending FormData, don't set Content-Type header,
          // the browser will set it to 'multipart/form-data' automatically
        });

        if (!response.ok) {
          throw new Error('Server responded with an error!');
        }

        // Optionally, handle the response data
        const responseData = await response.json();
        console.log('Server response:', responseData);

        // Update user context with the new profile picture URL
        if (responseData.profilePicUrl) {
          setUser({
            ...user,
            profilePicUrl: responseData.profilePicUrl
          });
        }
      } catch (error) {
        console.error('Error uploading the image:', error);
      }
    }
  };

  const handleSharePermissionChange = async (checked) => {
    try {

      const response = await fetch('/api/setUserSharePermission', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.userId, sharePermission: checked }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSharePermission(checked); // Update state only if request is successful
    } catch (error) {
      console.error('Error setting share permission:', error);
    }
  };


  const handleJoinClick =  async (e)  => {
    e.preventDefault();

    // Show the confirmation modal instead of submitting directly
    setShowConfirmationModal(true);
  };

  const handleConfirmJoin = async () => {
    setShowConfirmationModal(false);

    const formData = new FormData();
    /*formData.append('firstName', firstName);
    formData.append('lastName', lastName);*/
    formData.append('teamId', teamId);
    formData.append('sharePermission', sharePermission);
    formData.append('userId', user.userId);

    /*
    if (imageFile) {
      formData.append('image', imageFile); // Ensure the key matches with your backend expectation
    }
    */

    try {
      const response = await fetch('/api/addUserToTeam', {
        method: 'POST',
        body: formData, // send FormData
        // Do not set 'Content-Type' header, let the browser set it
      });

      const data = await response.json();
      if (data.success) {
        setShowModal(true);
      } else {
        console.error('Failed to join the team:', data.message);
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };



  if (sharePermission === undefined || loading) {
    return <div>Loading...</div>;
  }
  if (!team.id) { // Check if team is null indicating no team was found
    return (
      <div className="text-center">
        <p>Nothing found.</p>
      </div>
    );
  }
  return (
    <div>

          <div className="homeblock jointeamheaderblock text-center hidden">
            <h1 className="signinheader">Welcome to Yeess!</h1>
          </div>


          <form onSubmit={handleJoinClick}>
          <div className="vvblock">
            <div className="vvblocktitle">Join Team</div>
            <div className="vvinnerblock">
            You are invited to join
            <span className="font-bold"> {team.name ? (
              <span>{team.club_name} : {team.name}</span>
            ) : (
              <span>...</span>
            )}</span>.


              Check your profile details and click <b>Continue</b>:
            </div>


            {/*
            <div className="vvinnerblock">
              What's your name?
            </div>
              <input
                id="first-name"
                type="text"
                value={firstName}
                className="inputField inputFieldFirstHalf inputFieldJoinTeam"
                onChange={(e) => setFirstName(e.target.value)}
                required
                placeholder="First"
              />

              <input
                id="last-name"
                type="text"
                value={lastName}
                className="inputField inputFieldSecondHalf inputFieldJoinTeam"
                onChange={(e) => setLastName(e.target.value)}
                required
                placeholder="Last"

              />
              */}
              <div className="vvinnerblock">
                <div className="float-left profilesharetext">
                  Can we share your achievements on our social media and tag you?
                </div>
                <div className="float-right profilesharetoggle">
                <ToggleSwitch
                  id="share-permission"
                  name="toggleSwitch"
                  checked={sharePermission}
                  onChange={(newState) => handleSharePermissionChange(newState)}
                />
                </div>
                <br clear="all"/>
              </div>
              <div className="vvinnerblock">
                Connect your social accounts:
              </div>
              <div className="vvinnerblock">
                <div className="profileshareicons">
                  <img src="/images/profile_logo_fb.png"/>

                  <img src="/images/profile_logo_x.png"/>

                  <img src="/images/profile_logo_ig.png"/>

                  <img src="/images/profile_logo_tiktok.png"/>
                </div>
                <br clear="all"/>
              </div>


              <div className="vvinnerblock profileaddprofilepic">
                <input type="file" onChange={handleImageChange} style={{ display: 'none' }} id="fileInput" />
                <label htmlFor="fileInput" className="profileaddprofilepic_pic">
                  {user.profilePicUrl ? (
                    <img src={user.profilePicUrl} alt="User Profile" />
                  ) : (
                    <div>&#xa0;</div> // Placeholder if no picture is available
                  )}
                </label>
                <div className="profileaddprofilepic_text" onClick={() => document.getElementById('fileInput').click()}>
                {user.profilePicUrl ? (
                  <>Update profile picture</>
                ) : (
                  <>Add a profile picture</>
                )}
                </div>
              </div>

            </div>



            <button type="submit" className="jointeam-btn font-bold text-center bg-black" type="submit">Continue</button>

          </form>
          <br/><br/>

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div className="modal">
          <div className="modal-content text-center">
            <span className="close" onClick={() => setShowConfirmationModal(false)}>&times;</span>
            <h3 className="generalmodaltitle">INVITATION</h3>
            <br/>
            <div className="font-bold">You are invited to a team!</div>
            <br/>
            <p>Are you part of <b>{team.club_name}: {team.name}</b>?</p>
            <br/>
            <button className="big-btn" onClick={handleConfirmJoin}>Join Team</button>

            <div className="team_profile_pic bg-gray-200 hidden">&#xa0;</div>
            <br/><br/>
            <div className="underline cursor-pointer" onClick={() => setShowConfirmationModal(false)}>No, this is not my team, I don't want to join</div>
          </div>
        </div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <div class="generalmodaltitle">SUCCESS!</div>
            <br/>
            <p>You successfully joined the team {team.club_name}: <b>{team.name}</b>!</p>
            <br/><br/>
            <button className="big-btn" onClick={() => setShowModal(false)}>Ok</button>
          </div>
        </div>
      )}

    </div>

  );
}

export default UserJoinTeam;
