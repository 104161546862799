import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";

import axios from 'axios';
import { UserContext } from './App';
import Modal from './Modal'; // Assuming you have a Modal component

function Home() {
  const { user } = useContext(UserContext);
  const [userTrophies, setUserTrophies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [unseenTrophyCount, setUnseenTrophyCount] = useState(0); // Track the count of unseen trophies

  // Function to fetch trophies
  const fetchTrophies = () => {
    if (user.userId) {
      axios.get(`/api/getUserTrophies/${user.userId}`)
        .then(response => {
          const trophies = response.data;
          setUserTrophies(trophies);

          const unseenCount = trophies.filter(trophy => !trophy.trophy_seen).length;
          setUnseenTrophyCount(unseenCount);
          setShowModal(unseenCount > 0);
        })
        .catch(error => {
          console.error('There was an error fetching the trophies!', error);
        });
    }
  };

  useEffect(() => {
    fetchTrophies(); // Initial fetch

    // Set up polling
    const interval = setInterval(fetchTrophies, 10000); // Poll every 30 seconds

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [user.userId]);


  function getTrophyName(trophyName) {
    const nameMap = {
      'tiers1': 'gold',
      'tiers2': 'silver',
      'tiers3': 'bronze',
      'tiers4': 'participation',
      'tiers5': 'mvp'
    };

    return nameMap[trophyName] || trophyName; // default to trophyName if not found in the map
  }

  function randomRGB() {
    const r = Math.floor(Math.random() * 256); // Random between 0-255
    const g = Math.floor(Math.random() * 256); // Random between 0-255
    const b = Math.floor(Math.random() * 256); // Random between 0-255
    return `rgb(${r},${g},${b})`; // Return RGB string
  }



  // Corrected loop inside the modal rendering
  const trophiesForModal = unseenTrophyCount > 0 ? userTrophies.filter(trophy => !trophy.trophy_seen).map((trophy, index) => (
    <div key={index} className={`newtrophymodalimage trophy${index + 1}`}>
      <img
        src={`/images/collections/${trophy.trophystyle_id}/web/${getTrophyName(trophy.name)}.png`}
        alt={getTrophyName(trophy.name)}
      />
    </div>
  )) : [];


  // Call this function when the modal is dismissed by the user
  function handleModalClose() {
    // Set the trophies as seen in the backend
    axios.post('/api/setTrophiesAsSeen', { userId: user.userId })
      .then(response => {
        // Update the trophies as seen in the state
        /*setUserTrophies(prevTrophies =>
          prevTrophies.map(trophy => ({ ...trophy, trophy_seen: true }))
        );
        */
        setShowModal(false);
        setUnseenTrophyCount(0); // Reset the unseen trophy count

      })
      .catch(error => {
        console.error('Error setting trophies as seen:', error);
      });
  }

return (
    <>
      {showModal && (
        <Modal onClose={handleModalClose}>
          <div className="newtrophymodal">
            <h2 className="newtrophymodaltitle">BRAVO!</h2>
            {unseenTrophyCount === 1 ? ( // Check the count to determine singular or plural
              <>
              <p>You have been awarded a new trophy!</p>
              <div className="newtrophymodalimagewrapper">
                <div className="newtrophymodalimage">
                  {trophiesForModal}
                  &#xa0;
                </div>
                &#xa0;
              </div>
              <div className="newtrophymodalbutton" onClick={handleModalClose}>Check it out!</div>
              </>
            ) : (
              <>

              <p>You have been awarded {unseenTrophyCount} new trophies!</p>
              <div className="newtrophymodalimagewrapper">
                {trophiesForModal}
                &#xa0;
              </div>
              <div className="newtrophymodalbutton" onClick={handleModalClose}>Check them out!</div>
              </>
            )}
            {/* ... other modal contents */}
          </div>
        </Modal>
      )}
      <div className="homeblock text-center trophies" id="trophies">
        <div className="trophytitle">Trophies</div>
        <div className="trophiescontainer">
          {userTrophies.length === 0 ? (
            <p className="notrophies">You have no trophies yet!</p>
          ) : (
            userTrophies.map(trophy => {
              // Generate two random colors

              //const color1 = randomRGB();
              //const color2 = randomRGB();

              // Create a style object for the gradient
              //const gradientStyle = {
              //  background: `linear-gradient(to right bottom, ${color1}, ${color2})`
              //};

              return (
                <Link to={`/UserTrophy/${trophy.id}`} key={trophy.id} className="trophy bg-gray-200">
                  <div className={`trophy-name ${!trophy.trophy_seen ? 'trophy-name-unseen' : ''}`}>
                  <img
                      src={`/images/collections/${trophy.trophystyle_id}/web/${getTrophyName(trophy.name)}.png`}
                      alt={getTrophyName(trophy.name)}
                      />
                  </div>
                </Link>
              );
            })
          )}
          </div>
      </div>
    </>
  );
}

export default Home;
