import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AdminSignUp() {
  const navigate = useNavigate();
  const query = useQuery();
  const redirectTo = query.get('redirectTo') || '/SignIn?evt=dh18'; // Default to '/SignIn' if not specified

  // Form state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false); // State to manage the checkbox
  const [selectedClub, setSelectedClub] = useState(''); // State to store selected club
  const [clubs, setClubs] = useState([]); // State to store clubs data

  useEffect(() => {
    async function fetchClubs() {
      try {
        const response = await fetch('/api/listAllClubs');
        if (!response.ok) throw new Error('Failed to fetch clubs');
        const data = await response.json();
        setClubs(data); // Assuming the API returns an array of club objects
      } catch (error) {
        console.error('Error fetching clubs:', error);
      }
    }

    fetchClubs();
  }, []);

  function handleNotImplemented() {
    alert("This feature is disabled at the moment");
  }

  async function handleSignUp(event) {
    event.preventDefault();

    // Check if terms are accepted
    if (!termsAccepted) {
      alert('Please accept the terms and conditions.');
      return; // Prevent form submission
    }

    // Here you send a POST request to the backend
    try {
      const response = await fetch('/login/signupadminuser', { // Ensure this endpoint is correct
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, firstName, lastName, clubId: selectedClub }),
      });

      if (response.ok) {
        navigate(redirectTo); // Redirect the user after successful sign up
      } else {
        // Handle errors here, if the response is not ok
        console.error('Sign up failed.');
        
        alert("Email already exists");
        // You can set an error message to show to the user here
      }
    } catch (error) {
      // Handle the exception here
      console.error('There was an error signing up', error);
      // You can set an error message to show to the user here
    }
  }


  return (
    <>
      <link href="https://fonts.cdnfonts.com/css/sf-pro-display" rel="stylesheet"/>

      <div className="homeblock signinheaderblock text-center">
        <h1 className="signinheader">Sign Up</h1>
      </div>

      <form onSubmit={handleSignUp}> {/* Notice we changed this to use the onSubmit handler */}
        <input type="hidden" name="redirectTo" value={redirectTo} />

        <div className="homeblock text-center">
          <div className="signinblock">
            <section>
              <input
                className="inputFieldSignIn"
                id="first-name"
                name="firstName"
                type="text"
                placeholder="First Name"
                required
                autoFocus
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </section>
            <section>
              <input className="inputFieldSignIn" id="last-name" name="lastName" type="text" placeholder="Last Name" required value={lastName}
              onChange={(e) => setLastName(e.target.value)}/>
            </section>
            <section>
              <input className="inputFieldSignIn" id="email" name="email" type="email" autocomplete="email" placeholder="Email" required value={email}
              onChange={(e) => setEmail(e.target.value)}/>
            </section>
            <section>
              <input className="inputFieldSignIn" id="password" name="password" type="password" autocomplete="new-password" placeholder="Password" required value={password}
              onChange={(e) => setPassword(e.target.value)}/>
            </section>
            {/* Club Selection Dropdown */}
            <section>
              <select
                className="inputFieldSignIn"
                value={selectedClub}
                onChange={(e) => setSelectedClub(e.target.value)}
                required>
                <option value="">Select Your Club</option>
                {clubs.map((club) => (
                  <option key={club.id} value={club.id}>{club.name}</option>
                ))}
              </select>
            </section>
            {/* Optional: Add a password confirmation field */}
            {/* <section>
              <input className="inputFieldSignIn" id="confirm-password" name="confirmPassword" type="password" autocomplete="new-password" placeholder="Confirm Password" required/>
            </section> */}

            {/* Terms and Conditions Checkbox */}
             <section className="mt-4 text-left text-[#666666]">
               <label>
                 <input
                   type="checkbox"
                   checked={termsAccepted}
                   onChange={(e) => setTermsAccepted(e.target.checked)}
                 />
                 &nbsp;&nbsp;I accept the <Link to="/Terms" className="underline font-bold">Terms and Conditions</Link>.
               </label>
             </section>
          </div>

          <button className="signin-btn font-bold text-center bg-black" type="submit">Sign Up</button>
        </div>
      </form>

      <div className="homeblock socialloginblock text-center">
        <div className="sociallogintitle">
          Sign up with:
        </div>
        <div className="sociallogins">
        {/* Disable social logins for now
          <a href="/login/federated/google" className="signinball">
            <img src="/images/logo_google.png"/>
          </a>
        */}
        <div className="signinball" onClick={handleNotImplemented}>
          <img src="/images/logo_google.png"/>
        </div>
        {/*
        <a href="/login/federated/google" className="signinball">
          <img src="/images/logo_google.png"/>
        </a>

        <div className="signinball" onClick={handleNotImplemented}>
          <img src="/images/logo_fb.png"/>
        </div>
        <div className="signinball">
          <img src="/images/logo_apple.png" onClick={handleNotImplemented}/>
        </div>
        */}
        </div>
      </div>
      <br/>

      <div className="homeblock text-center">
        <p>Already have an account? <Link to="/SignIn" className="underline">Sign in</Link></p>
      </div>

      {/* If you're implementing social logins for sign-up as well, you can include them here. */}
    </>
  );
}

export default AdminSignUp;
