import React, { useState } from 'react';

const ToggleSwitch = ({ id, name, checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    const newState = !isChecked;
    setIsChecked(newState);
    onChange(newState);
  };

  return (
    <div className="flex items-center justify-center w-full">
      <label htmlFor={id} className="flex items-center cursor-pointer">
        <div className="relative">
          <input id={id} name={name} type="checkbox" className="sr-only" checked={isChecked} onChange={handleToggle} />
          <div className="toggleblock bg-gray-400 rounded-full"></div>
          <div className="toggledot absolute left-0 top-0 rounded-full transition"></div>
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
