import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './App';


function UserTrophies() {
  const [userTrophies, setUserTrophies] = useState([]);
  const { user, setUser } = useContext(UserContext);

  console.info(user);


  useEffect(() => {

    if (user.userId) {


      // Use the dynamic userId to make the API call
      axios.get(`/api/getUserTrophies/${user.userId}`)
        .then(response => {
          setUserTrophies(response.data);
        })
        .catch(error => {
          console.error('There was an error fetching the trophies!', error);
        });
    }
  }, [user.userId]);


  function getTrophyName(trophyName) {
    const nameMap = {
      'tier1': 'Gold',
      'tier2': 'Silver',
      'tier3': 'Bronze',
      'tier4': 'Participation',
      'tier5': 'MVP'
    };

    return nameMap[trophyName] || trophyName; // default to trophyName if not found in the map
  }

  return (
    <>
      <div className="homeblock">
      <div className="user-info">
        <div id="usernameui" className="font-bold">{user.firstName} {user.lastName}</div>
        <br clear="all"/>
        <div id="userrole">{user.userId} - Girls 2014</div>
      </div>
      </div>

      <div className="homeblock">

      <h2 className="font-bold text-3xl">TROPHIES</h2>
      <br/>
      <div className="trophies-grid">
        {userTrophies.map(trophy => (
          <div key={trophy.id} className="trophy bg-gray-200">
            {/*
            <div className="trophy-image" style={{ backgroundImage: `url(${trophy.image_url})` }}></div>
            */}
            <div className="trophy-name">{getTrophyName(trophy.name)}</div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
}

export default UserTrophies;
